import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

export default new Vuex.Store({
  strict: false,
  plugins: [vuexLocalStorage.plugin],
  state: {
    selectedLang: null,
    botName: null,
    chatBotID: null,
    response: null,
    optionID: null,
    chatDetails: [],
    selectedOption: null,
    list: [],
    user: null,
  },
  mutations: {
    setSelectedLang(state, n) {
      state.selectedLang = n;
    },
    setBotName(state, n) {
      state.botName = n;
    },
    setChatBotID(state, n) {
      state.chatBotID = n;
    },
    setResponse(state, n) {
      state.response = n;
    },
    setOptionID(state, n) {
      state.setOptionID = n;
    },
    setChatDetails(state, n) {
      state.chatDetails.push(n);
    },
    clearChatDetails(state, n) {
      state.chatDetails = [];
    },
    setSelecedOption(state, n) {
      state.selectedOption = n;
    },
    setUser(state, n) {
      state.user = n;
    },
    clearList(state) {
      state.list = [];
    },
    setList(state, n) {
      if (state.list.length > 0) {
        var found = false;
        state.list.forEach((element) => {
          if (element.id === n.id) {
            found = true;
          }
        });

        if (!found) state.list.push(n);
      } else {
        state.list.push(n);
      }
    },
  },
  actions: {},
  modules: {},
});
