<template>
  <div id="app">
    <div class="" v-if="$route.name != 'login'">
      <div
        v-if="$route.name != 'preview'"
        id="navbar"
        class="myNavv fullWidth botom"
      >
        <Navbar />
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import Navbar from "./components/navbar/Navbar.vue";

export default {
  name: "App",
  components: {
    Navbar,
  },
  data() {
    return {};
  },
  created() {
    console.log(this.$route.name);
  },
};
</script>

<style scoped>
.myBg {
  background: url("./assets/bgg.svg");
  height: 12vh;
  /* margin-bottom: 4rem; */
}
.maxWidth {
  max-width: var(--maxWidth);
  margin: 0 auto;
  padding: 0 var(--myPadding);
}

.botom {
  margin-bottom: 2rem;
}

.confetti {
}
.myBorder {
  border: 1px solid grey;
}
.sectionTwo {
  grid-area: sectionThree;
}
.myTermss {
  background: lightcyan;
  grid-area: term;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  width: 100vw;
  height: var(--termsHeight);
}
.myNavv {
  background: #fff;
  grid-area: nav;
  height: var(--navHeight);
  position: sticky;
  max-width: var(--maxWidth);
  margin: 0 auto;
  /* padding-bottom: ; */
  transition: max-width 0.2s, height 0.2s;
  webkit-transition: max-width 0.2s, height 0.2s;
  -moz-transition: max-width 0.2s, height 0.2s;
  -ms-transition: max-width 0.2s, height 0.2s;
  -o-transition: max-width 0.2s, height 0.2s;
}
.fullWidth {
  max-width: 100vw;
  height: var(--navHeightSmall);
  box-shadow: 0 3px 15px 3px #ccc;
}
.myBanner {
  background: pink;
  grid-area: banner;
}
.myProperty {
  background: palevioletred;
  grid-area: property;
  padding: 0 var(--myPadding);
}
.previousWinner {
  background: pink;
  grid-area: previousWinner;
}
.myKeyring {
  background: lightblue;
  grid-area: myKeyring;
}
.myStory {
  background: lightseagreen;
  grid-area: myStory;
}
.myCharity {
  background: palevioletred;
  grid-area: myCharity;
}

.lookingToSell {
  background: lightseagreen;
  grid-area: lookingToSell;
}

.footer {
  background: lightseagreen;
  grid-area: footer;

  max-width: var(--maxWidth);
  margin: 0 auto;
  height: var(--footer);
}

.newContainer {
  max-width: var(--maxWidth);
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  background: red;
}

.myGrid {
  max-width: var(--maxWidth);
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(5, var(--cardsHeight)) var(--previousWinners);
  grid-column-gap: 1rem;

  grid-template-areas:
    "banner banner"
    "property property"
    "myKeyring myKeyring"
    "myStory myCharity"
    "lookingToSell lookingToSell"
    "previousWinner previousWinner";
}
.sectionThree {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, 3fr);
}

.sectionGap {
  margin-bottom: 2.5rem;
}
</style>
