<template>
  <div class="">
    <!-- modal -->

    <div v-if="modal" class="modall" title="Add New Language">
      <!-- <row> -->
      <div class="widthh">
        <div class="class__add">
          <label class="addLanguages" for=""> Add Languages </label>
          <div class="cross"><p class="para_cross" @click="close">x</p></div>
        </div>
        <div class="modal_iner">
          <div class="text_outr">
            <input
              class="text_iner"
              v-model="text"
              type="text"
              placeholder="Enter Language"
            />
          </div>

          <!-- Dropdown -->
          <div v-for="btns in btnss" :key="btns.id">
            <button
              :class="selectedBtn == btns.id ? 'modal_btn' : 'unSelectedBtn'"
              @click="selectedButton(btns.id)"
            >
              {{ btns.language }}
            </button>
          </div>
        </div>
        <div class="" style="width: 100%">
          <button class="save_btn" @click="close">Save</button>
        </div>
      </div>
      <!-- </row> -->
    </div>

    <!-- modal end -->
<!-- <div v-for="lang in tableData "  :key="lang.id" style="margin-top:10px;" class="card">
  <div  class="main-container">
      <div class="heading">
        <h2 class="heading_iner">{{ lang.name }}</h2>
      </div>
      <div class="flexx">
        <div class="side">
          <p class="paraa">
            Created on - 12 Sep, 2021 <br />
            {{lang.sequence  }}
          </p>
        </div>
        <div class="btns">
          <button class="btn">Manage</button>
          <button class="btn" @click="modal_open">Edit</button>
        </div>
      </div>
    </div>
</div> -->
  
  </div>
</template>
<script>
// import Multiselect from "vue-multiselect";

export default {
  components: {
    // Multiselect,
  },
  mounted()
  {
  this.LoadData();
  },
  created()
  {
  },
  data() {
    return {
      tableData:[],
      modal: false,
      text: "",
      btnss: [
        { language: "Right", id: 1 },
        { language: "left", id: 2 },
      ],
      selectedBtn: 1,

      value: null,
      options: [{ name: "Left" }, { name: "Right" }],
    };
  },
  methods: {
     LoadData()
    {
  var axios = require('axios');
  var config = {
    method: 'get',
    url: 'https://raaji.fastech.pk/api/Languages',
    headers: {
        Authorization: 'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJNdWhhbW1hZCIsImVtYWlsIjoiYWxpMTIzQGdtYWlsLmNvbSIsImp0aSI6ImI0ODJjYjIyLTg0YjctNDZmMS05ODQ4LTdkODRjMjI2YjExMSIsImV4cCI6MTY5OTUzMDc1NCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.Emareb1_zFV8KHVLGv2GJABnDlkm5JONtWAYATT5OjM' 
    }
  };

  axios(config)
  .then( (response) => {
    console.log(response.data);

     this.tableData  =  response.data.data;
    // this.images  =  response.data.data.images;

     console.log(response.data.data);

 })
  .catch(function (error) {
     swal.fire({
              type: 'error',
               title: '  ' + error.message,
              text: 'Something went wrong with the connection, Please try again later!',
            })
  });
    },
    
    selectedButton(id) {
      this.selectedBtn = id;
      console.log(this.selectedBtn);
    },
    modal_open() {
      this.modal = true;
    },

    close() {
      this.modal = false;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.card {
  grid-area: card;
  height: 9rem;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border-radius: 15px;
  /* max-width: 960px; */
  /* position: ; */
  /* margin-top: 2rem; */
}
.heading_iner {
  font-size: 32px;
  /* line-height: 0px; */
  font-weight: 600;
  color: #93117d;
}
.cross {
  background-color: #93117d;
  border-radius: 39px;
  padding: 9px;
  height: 28px;
}
.para_cross {
  color: #fff;
  margin-top: -10px;
  /* font-size: px; */
}
.unSelectedBtn {
  background-color: #fff;
  color: #93117d;
  border-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  padding: 12px 16px;
  border-radius: 12px;
  /* border: 2px solid #93117d; */
  border-radius: 10px;
  font-weight: 500;
  margin: 0 6px;
  font-size: 16px;
  font-weight: 100;

  width: 9rem;
  cursor: pointer;
}
.text_iner {
  padding: 10px 12px;
  border-radius: 12px;
  margin-bottom: 1rem;
  width: 500px;
}
.addLanguages {
  font-size: 24px;
  /* margin: 4rem 0; */
  color: #93117d;
}
.modal_iner {
  display: flex;
  justify-content: space-between;
}
.class__add {
  margin-bottom: 1.6rem;
  display: flex;
  justify-content: space-between;
}
.save_btn {
  background-color: #93117d;
  padding: 12px 16px;
  border-radius: 12px;
  /* border: 2px solid #93117d; */
  border-radius: 10px;
  margin: auto;
  display: block;
  font-size: 16px;
  font-weight: 100;
  color: white;
  width: 19rem;
  cursor: pointer;
}
.modal_btn {
  background-color: #93117d;
  padding: 12px 16px;
  border-radius: 12px;
  /* border: 2px solid #93117d; */
  border-radius: 10px;
  font-weight: 500;
  margin: 0 6px;
  font-size: 16px;
  font-weight: 100;
  color: white;
  width: 9.8rem;
  cursor: pointer;
}
.widthh {
  width: 880px;
  /* bottom: 0; */
}
.paraa {
  font-size: 17px;
  color: gray;
  line-height: 1.4rem;
}
.modall {
  cursor: pointer !important;
}
.modall {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* box-shadow-padding:; */
  padding: 2rem;
  border-radius: 15px;
  width: fit-content;
  margin: auto;
  position: absolute;
  /* top: 50%; */
  bottom: -6.8rem;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  z-index: 1;
}
.typo__label {
  margin-top: 1rem !important;
}
.main-container {
  padding: 1rem 3rem;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
}
.flexx {
  display: flex;
  justify-content: space-between;
  margin-top: -2rem;
}
.heading {
  margin-bottom: 2rem;
  /* line-height: 0px; */
}

.btns {
  /* margin-top: 1rem; */
}
.btn {
  background-color: white;
  padding: 14px 16px;
  border-radius: 12px;
  border: 2px solid #93117d;
  border-radius: 10px;
  margin: 0 6px;
  font-size: 16px;
  font-weight: 100;
  color: #000;
  width: 6rem;
  cursor: pointer;
}
.btn:hover {
  background-color: #93117d;
  color: #fff;
}
</style>
