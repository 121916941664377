<template>
  <div class="myBg">
    <div class="btnContainerr">
      <!-- <div class="btns">
        <button class="add_btn" @click="modal_open">Create New</button>
      </div> -->
    </div>
    <div class="myGrid">
      <div class="card">
        <div class="main-container">
          <div class="heading">
            <h2 class="heading_iner">Englsih Bot</h2>
          </div>

          <div class="chat_sys">
            <userChat text="My name is Raaji And I am very happy to meet you" />
          </div>
          <div class="img">
            <img
              src="https://www.bksb.co.uk/wp-content/uploads/2000/03/girls-studying.jpg"
              alt=""
              class="img_inner"
            />
          </div>
          <div class="model_outer">
            <div class="modal_iner">
              <!-- Dropdown -->
              <div v-for="btns in btnss" :key="btns.id">
                <button
                  :class="
                    selectedBtn == btns.id ? 'modal_btn' : 'unSelectedBtn'
                  "
                  @click="selectedButton(btns.id)"
                >
                  {{ btns.language }}
                </button>
              </div>
            </div>
          </div>

          <!-- white hover placment -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import userChat from "../userChat/userChat.vue";
export default {
  components: {
    userChat,
  },
  data() {
    return {
      modal: false,
      text: "",
      btnss: [
        { language: "Introduce yourself", id: 1 },
        { language: "Where are you from?", id: 2 },
        { language: "What do you do?", id: 3 },
        { language: "How long you've been doing this?", id: 4 },
      ],
      value: null,
      selectedBtn: 0,
      options: [{ name: "Left" }, { name: "Right" }],
    };
  },
  methods: {
    modal_open() {
      this.modal = true;
    },
    selectedButton(id) {
      this.selectedBtn = id;
      console.log(this.selectedBtn);
      this.$router.push("/information");
    },
    close() {
      this.modal = false;
    },
    createBot() {
      this.$router.push("/information");
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.btnContainerr {
  /* max-width: var(--maxWidth); */
  max-width: 960px;
  /* background-color: aqua; */
  padding-top: 2rem;

  margin: auto;
}

.myGrid {
  /* max-width: var(--maxWidth); */
  margin: 0 auto;
  max-width: 590px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10rem, 10rem;
  gap: 1rem;
  padding-top: 2rem;

  grid-template-areas: "card card ";
}

.myBg {
  height: 90vh;
  overflow: hidden;
}
.card {
  border: 8px solid #93117d;
  grid-area: card;
  position: relative;
  height: 40rem;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border-radius: 15px;
}
.heading_iner {
  font-size: 22px;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #93117d;
}
/* .chat_sys {
  margin: 2rem 0;
} */
.bottom_class {
  position: absolute;
  bottom: 0;

  width: 500px;
}
.width_full {
  width: 100%;
  display: flex;
  justify-content: center;
}

.typo__label {
  margin-top: 1rem !important;
}
.main-container {
  padding: 1rem 1rem;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
}
.flexx {
  display: flex;
  /* justify-content: space-between; */
  margin: 2rem 0;
}

.img {
  display: flex;
  justify-content: center;
}
.img_inner {
  width: 360px;
  height: 180px;
  border-radius: 12px;
}
.modal_btn {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  background-color: #93117d;
  padding: 6px 11px;
  border-radius: 12px;
  /* border: 2px solid #93117d; */
  border-radius: 15px;
  font-weight: 500;
  margin: 14px 6px;
  font-size: 15px;
  font-weight: 100;
  color: white;
  /* width: 9rem; */
  cursor: pointer;
}
.unSelectedBtn {
  background-color: #fff;
  color: #93117d;
  border-color: #93117d;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  padding: 6px 11px;

  border-radius: 12px;
  /* border: 2px solid #93117d; */
  border-radius: 15px;
  font-weight: 500;
  margin: 14px 6px;
  font-size: 15px;
  font-weight: 100;

  /* width: 9rem; */
  cursor: pointer;
}
.modal_iner {
  margin: 12px 0;
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}
.model_outer {
  margin: 0 1rem;
}
</style>
