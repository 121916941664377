<template>
  <div class="myContainer myNavbar">
    <div class="myFlex">
      <img
        src="../../assets/logoo.svg"
        alt="Raaji"
        class="myLogo"
        @click="Home()"
      />

     
        <ul>
          <router-link to="/">
            <li class="active">Languages</li>
          </router-link>
          <router-link to="/user">
            <li>Users</li>
          </router-link>
          <router-link to="/chatbot">
            <li>Chatbot</li>
          </router-link>
       
        <li>
        <div class="dropdown">
          <button class="dropbtn"> Account </button>
          <div class="dropdown-content">
          <a href="#/ChangeP">Change password</a>
          <a @click="Logout()">Logout</a>
          </div>
        </div>
        </li>

          <!-- <a @click="Logout()">
            <li>Logout</li>
          </a> -->

          <!-- <img src="../../assets/cart.png" class="carticon" /><sub
            class="cart"
            >{{ getTotalCartCount }}</sub
          > -->
        </ul>
    
    </div>
  </div>
</template>

<script>

export default {
  
  name: "Navbar",
   components: {
  
  },
  
  data() {
    return {
      getTotalCartCount: 2,
    };
  },
  created() {
    console.log(this.$route.name);
  },
  methods: {
    Home() {
      this.$router.push("/");
    },

    Logout() {
      this.$store.commit("setUser", null);
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.dropbtn {
  line-height: 1.6;
  font-family: "DINNextRoundedLTW01-Bold", "sans-serif";
  color: #2c3e50;
  background-color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #f1f1f1}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #93117d;
  color: white;

}
.cart {
  cursor: pointer;
  margin-top: 30px;
  margin-left: -20px;
  bottom: -0.9vw;
  right: 1vw;
  background-color: #ff6700;
  color: white;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-size: 1rem;
  border: none;
}
.router-link-exact-active {
  color: #93117d;
}

/* cart icon */
.carticon {
  width: 45px;
  cursor: pointer;
}

.sectionOne {
  height: 500px;
  border: 2px solid green;
  background: url("../../assets/confetti.svg");
}
</style>
