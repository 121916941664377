<template>
  <div class="myBg" id="preview">
    <div class="myGrid">
      <div class="card">
        <div
          style="
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            margin-bottom: 10px;
          "
        >
          <div class="avatar_main">
            <img
              class="avatar_inner_admin"
              src="https://auratraaj.com/englishmhm/img/raaji.png"
              alt=""
            />
            <div class="admin_msg_outer">
              <p style="" class="admin_msg">Raaji</p>
              <p style="color: #05b113; font-size: 12px; margin-top: -5px">
                online
              </p>
            </div>
          </div>

          <div
            style="
              width: 100%;
              display: flex;
              position: absolute;
              align-items: flex-end;
              justify-content: flex-end;
              padding-bottom: 50px !important;
            "
          >
            <div class="admin_msg_mute">
              <i
                v-if="this.mute"
                style="margin-top: 30px"
                class="fas fa-volume-mute"
                @click="MuteVoice()"
              ></i>
              <i
                v-if="!this.mute"
                style="margin-top: 30px"
                class="fas fa-volume-up"
                @click="MuteVoice()"
              ></i>
            </div>
          </div>
        </div>

        <div class="main-container">
          <!-- Right side -->
          <div
            v-if="this.side === 'Right'"
            class="scrollAble"
            id="container"
            ref="container"
          >
            <div v-if="!this.loaded">
              <div
                class="model_outer"
                style=" width: 100%;
                display: flex;
                align-items: flex-end
                justify-content: flex-end;
                flex-direction: column;"
              >
                <div class="modal_iner" style="margin-left: 2.5rem">
                  <!-- Dropdown -->
                  <button
                    class="unSelectedBtn"
                    style="margin-bottom: 5px"
                    @click="LoadData()"
                  >
                    آئیے شروع کرتے ہیں
                  </button>
                </div>
              </div>
            </div>

            <div v-for="item in myList" :key="item.chatDetail.id">
              <div v-if="item.chatDetail.userMessage != ''" class="chat_sys">
                <adminChatLeft :text="item.chatDetail.userMessage" />
              </div>

              <div class="chat_sys">
                <userChatRight
                  :text="item.chatDetail.adminMessage"
                  optionText=""
                />
              </div>
              <div
                v-if="item.chatDetail.media != ''"
                class="img"
                style="border: red"
              >
                <img :src="item.chatDetail.media" alt="" class="img_inner" />
              </div>
              <div
                class="model_outer"
                style=" width: 100%;
      display: flex;
      align-items: flex-end
      justify-content: flex-end;
      flex-direction: column;"
              >
                <div class="modal_iner" style="margin-left: 2.5rem">
                  <!-- Dropdown -->
                  <div v-for="btns in item.options" :key="btns.id">
                    <button
                      :class="
                        selectedBtn == btns.id ? 'modal_btn' : 'unSelectedBtn'
                      "
                      style="margin-top: 0px; margin-bottom: 5px"
                      @click="selectOption(btns.id)"
                    >
                      {{ btns.option }}
                    </button>
                  </div>
                </div>
              </div>

              <!-- <div v-if="showResponse" class="">
              <adminChat :text="this.adminText" />
            </div> -->
            </div>

            <!-- <div id="msgContainer" ref="msgContainer">asdas</div> -->
          </div>

          <!-- Left side -->
          <div
            v-if="this.side === 'Left'"
            class="scrollAble"
            id="container"
            ref="container"
          >
            <div v-if="!this.loaded">
              <div
                class="model_outer"
                style=" width: 100%;
      display: flex;
      align-items: flex-start
      justify-content: flex-end;
      flex-direction: column;"
              >
                <div class="modal_iner">
                  <!-- Dropdown -->
                  <button class="unSelectedBtn" @click="LoadData()">
                    Let's Start
                  </button>
                </div>
              </div>
            </div>

            <div v-for="item in myList" :key="item.chatDetail.id">
              <div v-if="item.chatDetail.userMessage != ''" class="chat_sys">
                <adminChat :text="item.chatDetail.userMessage" />
              </div>

              <div class="chat_sys">
                <userChat :text="item.chatDetail.adminMessage" optionText="" />
              </div>
              <div v-if="item.chatDetail.media != ''" class="img">
                <img :src="item.chatDetail.media" alt="" class="img_inner" />
              </div>
              <div class="model_outer">
                <div class="modal_iner" style="margin-left: 2.5rem">
                  <!-- Dropdown -->
                  <div v-for="btns in item.options" :key="btns.id">
                    <button
                      class="unSelectedBtn"
                      style="margin-top: 0px; margin-bottom: 5px"
                      @click="selectOption(btns.id)"
                    >
                      {{ btns.option }}
                    </button>
                  </div>
                </div>
              </div>

              <!-- <div v-if="showResponse" class="">
              <adminChat :text="this.adminText" />
            </div> -->
            </div>

            <!-- <div id="msgContainer" ref="msgContainer">asdas</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import userChat from "../userChat/userChat.vue";
import userChatRight from "../userChat/userChatRight.vue";
import adminChat from "../adminChat/adminChat.vue";
import adminChatLeft from "../adminChat/adminChatLeft.vue";
import axios from "axios";
var audio = document.createElement("audio");
export default {
  components: {
    userChat,
    adminChat,
    userChatRight,
    adminChatLeft,
  },
  data() {
    return {
      mute: false,
      myaudio: "",
      audio: null,
      modal: false,
      loaded: false,
      title: null,
      text: "",
      myList: [],
      btnss: [
        { language: "Introduce yourself", id: 1 },
        { language: "Your knowledge", id: 2 },
        { language: "Your values", id: 3 },
      ],
      btnsTwo: [
        { language: "Who made you?", id: 4 },
        { language: "Where are you from?", id: 5 },
        { language: "What do you like?", id: 6 },
      ],
      showResponse: false,
      showResponseBody: false,
      adminText: "...",
      value: null,
      selectedBtn: 0,
      options: [{ name: "Left" }, { name: "Right" }],
      side: null,
      currentAudio: null,
    };
  },
  methods: {
    MuteVoice() {
      if (this.mute == true) {
        this.mute = false;
        audio.volume = 1.0;
        console.log(this.mute);
        if (
          this.myaudio != "" &&
          this.myaudio != null &&
          this.myaudio != undefined
        ) {
          // var audio = new Audio(this.myaudio);
          // audio.play();
        }
      } else if (this.mute == false) {
        this.currentAudio = null;
        this.mute = true;
        audio.volume = 0.0;
        // audio.setAttribute("src", this.currentAudio); //change the source
        // audio.load(); //load the new source
        // audio.play(); //play
        if (
          this.myaudio != "" &&
          this.myaudio != null &&
          this.myaudio != undefined
        ) {
          // var audio = new Audio(this.myaudio);
          // console.log("Hello", audio.muted);
          // audio.muted = true;
        }
        console.log(this.mute);
      }
    },
    modal_open() {
      this.modal = true;
    },
    selectOption(id) {
      if (id !== null) {
        console.log(id);

        var config = {
          method: "get",
          url:
            "https://raaji.fastech.pk/api/ChatDetails/GetMessageByOption/" + id,
          headers: {
            Authorization:
              "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJNdWhhbW1hZCIsImVtYWlsIjoiYWxpMTIzQGdtYWlsLmNvbSIsImp0aSI6ImI0ODJjYjIyLTg0YjctNDZmMS05ODQ4LTdkODRjMjI2YjExMSIsImV4cCI6MTY5OTUzMDc1NCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.Emareb1_zFV8KHVLGv2GJABnDlkm5JONtWAYATT5OjM",
          },
        };

        // console.log(
        //   "https://raaji.fastech.pk/api/ChatDetails/GetMessageByOption/" + id
        // );

        axios(config)
          .then((response) => {
            this.myList.push(response.data.data);
            window.setTimeout(function () {
              var elem = document.getElementById("container");
              elem.scrollTop = elem.scrollHeight;
            }, 1000);

            if (!this.mute) {
              this.currentAudio = response.data.data.chatDetail.audio;
              audio.setAttribute("src", this.currentAudio); //change the source
              audio.load(); //load the new source
              audio.play(); //play
              // var audio = new Audio(this.currentAudio);
              // audio.play();
            }

            let element = document.getElementById("msgContainer");
            element.scrollIntoView(false);

            //var container = this.$refs.msgContainer;
            // container.scrollTop = container.scrollHeight + 120;
          })
          .catch(function (error) {
            console.error(error);
            this.$swal.fire({
              type: "error",
              title: "  " + error.message,
              text: "Something went wrong with the request, please try later.",
            });
          });
      }
    },
    close() {
      this.modal = false;
    },
    createBot() {
      //   this.$router.push("/createBot");
    },
    mockupResponse() {
      setTimeout(() => {
        this.adminText = "Tell us something about yourself?";
        this.showResponseBody = true;
      }, 2000);
    },

    async LoadData() {
      var myURL = this.$route.params.name;

      var config = {
        method: "get",
        url: "https://raaji.fastech.pk/api/ChatBots/GetByUrl/" + myURL,
        headers: {
          Authorization:
            "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJNdWhhbW1hZCIsImVtYWlsIjoiYWxpMTIzQGdtYWlsLmNvbSIsImp0aSI6ImI0ODJjYjIyLTg0YjctNDZmMS05ODQ4LTdkODRjMjI2YjExMSIsImV4cCI6MTY5OTUzMDc1NCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.Emareb1_zFV8KHVLGv2GJABnDlkm5JONtWAYATT5OjM",
        },
      };

      //console.log("https://raaji.fastech.pk/api/ChatBots/GetByUrl/" + myURL);

      axios(config)
        .then((response) => {
          this.loaded = true;
          this.myList.push(response.data.data);

          if (
            response.data.data.chatDetail.audio != "" &&
            response.data.data.chatDetail.audio != null &&
            response.data.data.chatDetail.audio != undefined
          ) {
            this.myaudio = response.data.data.chatDetail.audio;
            console.log(response.data.data.chatDetail.audio);
            // var audio = new Audio(response.data.data.chatDetail.audio);
            // audio.play();
            if (this.mute == false) {
              this.currentAudio = response.data.data.chatDetail.audio;
              audio.setAttribute("src", this.currentAudio); //change the source
              audio.load(); //load the new source
              audio.play(); //play
            } else {
              console.log("not");
              this.currentAudio = response.data.data.chatDetail.audio;
              audio.setAttribute("src", this.currentAudio); //change the source
              audio.load(); //load the new source
              audio.play(); //play
            }
          }
        })
        .catch(function (error) {
          console.error(error);
          this.$swal.fire({
            type: "error",
            title: "  " + error.message,
            text: "Something went wrong with the request, please try later.",
          });
        });
    },

    PlayVoice(voc) {
      // Plays a note, selected via getNote()
      //let note = new Audio(this.getNote()); // or
      let note = new Audio(voc);
      // note.play(); // new Audio will load asynchronously the audio
      // so instead of play directly after create note, you can listen for
      // a event and wait for it to know that the sound was loaded and can be
      // played

      // listen for the canplaythrough event
      note.addEventListener("canplaythrough", () => {
        /* the audio is now playable; play it if permissions allow */
        note.play(); // the audio now can be played
      });
    },

    async LoadTitle() {
      var myURL = this.$route.params.name;

      var config = {
        method: "get",
        url: "https://raaji.fastech.pk/api/ChatBots/ByUrl/" + myURL,
        headers: {
          Authorization:
            "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJNdWhhbW1hZCIsImVtYWlsIjoiYWxpMTIzQGdtYWlsLmNvbSIsImp0aSI6ImI0ODJjYjIyLTg0YjctNDZmMS05ODQ4LTdkODRjMjI2YjExMSIsImV4cCI6MTY5OTUzMDc1NCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.Emareb1_zFV8KHVLGv2GJABnDlkm5JONtWAYATT5OjM",
        },
      };

      axios(config)
        .then((response) => {
          this.title = response.data.data.title;
          this.side = response.data.data.language.sequence;
        })
        .catch(function (error) {
          this.$swal.fire({
            type: "error",
            title: "  " + error.message,
            text: "Something went wrong with the request, please try later.",
          });
        });
    },
  },
  created() {
    this.LoadTitle();
  },
  mounted: function () {
    this.$watch("track", () => {
      this.$refs.player.load();
    });
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.avatar {
  align-items: flex-end;
  display: flex;
  margin: 0 0rem;
  /* position: relative; */
  /* top: 1.2rem; */
}
.avatar_main {
  align-items: flex-end;
  display: flex;
  margin: 0 0rem;
  position: relative;
  top: 1.2rem;
}
.avatar_inner_admin {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  margin-bottom: 5px;
  border: 2px solid #45d258;
}
.admin_msg_outer {
  color: #93117d;
  margin-left: 10px;
  /* left: 0.2rem; */
  /* position: relative; */
}
.admin_msg_mute {
  color: #93117d;
  margin-right: 35px;
  /* left: 0.2rem; */
  position: relative;
}

.admin_msg {
  font-size: 18px;
}
.btns {
  padding-top: 2rem;
  max-width: 960px;
}
.avatar {
  align-items: flex-end;
  display: flex;
  position: relative;
  /* top: 1.2rem; */
}
.btn {
  background-color: white;
  padding: 14px 16px;
  border-radius: 12px;
  border: 2px solid #93117d;
  border-radius: 10px;
  margin: 0 0px;
  font-size: 16px;
  font-weight: 100;
  color: #000;
  width: 6rem;
  cursor: pointer;
}
.add_btn {
  margin: 2rem 0;
  max-width: 960px;

  background-color: white;
  padding: 14px 16px;
  border-radius: 12px;
  border: 2px solid #93117d;
  border-radius: 10px;
  margin: 0 6px;
  font-size: 16px;
  font-weight: 100;
  color: #000;
  width: 9rem;
  cursor: pointer;
}
.btn:hover {
  background-color: #93117d;
  color: #fff;
}
.add_btn:hover {
  background-color: #93117d;
  color: #fff;
}

.myBorder {
  border: 2px solid red;
}

.btnContainerr {
  /* max-width: var(--maxWidth); */
  max-width: 960px;
  /* background-color: aqua; */
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  margin: auto;
}

.myGrid {
  /* max-width: var(--maxWidth); */
  margin: 0 auto;
  max-width: 590px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10rem, 10rem;

  gap: 1rem;
  padding-top: 2rem;
  margin-top: -30px !important;
  grid-template-areas: "card card ";
}

.myBg {
  background: url("../../assets/bg.png");
  height: 100vh;
  overflow: hidden;
}
.card {
  border: 8px solid #93117d;
  grid-area: card;
  position: relative;
  height: 43rem;
  padding-left: 1rem;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border-radius: 15px;
}
.scrollAble {
  overflow-x: auto;
  max-height: 34rem;
}
.heading_iner {
  font-size: 22px;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #93117d;
}
/* .chat_sys {
  margin: 2rem 0;
} */
.bottom_class {
  position: absolute;
  bottom: 0;

  width: 500px;
}
.width_full {
  width: 100%;
  display: flex;
  justify-content: center;
}

.typo__label {
  margin-top: 1rem !important;
}
.main-container {
  padding: 1rem 1rem;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
}
.flexx {
  display: flex;
  /* justify-content: space-between; */
  margin: 2rem 0;
}

.img {
  display: flex;
  justify-content: center;
}
.img_inner {
  width: 360px;
  height: 180px;
  border-radius: 12px;
  object-fit: contain;
}
.modal_btn {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  background-color: #93117d;
  padding: 6px 11px;
  border-radius: 12px;
  /* border: 2px solid #93117d; */
  border-radius: 15px;
  font-weight: 500;
  margin: 14px 6px;
  font-size: 15px;
  font-weight: 100;
  color: white;
  /* width: 9rem; */
  cursor: pointer;
}
.unSelectedBtn {
  background-color: #e68b00;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 20px;
  margin: 14px 6px;
  font-size: 15px;
  cursor: pointer;
}
.modal_iner {
  margin: 12px 0;
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}
.logo {
  width: 256px;
  display: block;
  margin: 0 auto;
}
.model_outer {
}

@media screen and (max-width: 500px) {
  .myGrid {
    display: block !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .btnContainerr {
    display: none !important;
  }
  .card {
    border: none !important;
    border-radius: 0 !important;
    height: 100vh !important;
  }
  .img_inner {
    width: 315px;
    height: 180px;
    border-radius: 12px;
    margin-left: 1rem;
  }
  .logo {
    width: 160px;
    display: block;
    margin: 0 auto;
  }
  .modal_iner {
    margin-left: 0.8rem !important;
  }
  .myBg {
    background: url("");
    background-color: #fff;

    height: 100vh !important;
  }
}

@media only screen and (max-width: 1366px) {
  .card {
    height: 36rem;
  }
  .scrollAble {
    overflow-x: hidden;
    /* margin-top: 1rem; */
    max-height: 78vh;
  }
}
</style>
