<template>
  <div class="myBg">
    <div class="myGrid">
      <div class="card">
        <div class="main-container">
          <div class="heading">
            <h2 class="heading_iner">{{ this.$store.state.botName }}</h2>
          </div>

          <div v-for="item in myData" :key="item.chatDetail.id">
            <div class="chat_sys" style="cursor: pointer" @click="editFirst()">
              <userChat
                :text="item.chatDetail.adminMessage"
                :optionText="item.chatDetail.options"
              />
            </div>
            <i
              style="margin-left: 2.5rem"
              v-if="item.chatDetail.audio"
              @click="Play(item.chatDetail.audio)"
              class="fas fa-play"
            ></i>
            <div v-if="item.chatDetail.media != ''" class="img">
              <img :src="item.chatDetail.media" alt="" class="img_inner" />
            </div>
            <div class="model_outer">
              <div class="modal_iner" style="margin-left: 2.5rem">
                <!-- Dropdown -->
                <div v-for="btns in item.options" :key="btns.id">
                  <button
                    :class="
                      selectedBtn == btns.id ? 'modal_btn' : 'unSelectedBtn'
                    "
                    style="margin-top: 0px; margin-bottom: 5px"
                    @click="selectOption(btns)"
                  >
                    {{ btns.option }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- white hover placment -->
        </div>
      </div>
    </div>

    <div class="btns">
      <button class="add_btn" @click="Publish()">Publish</button>
    </div>
  </div>
</template>
<script>
import userChat from "../userChat/userChat.vue";
import axios from "axios";
export default {
  components: {
    userChat,
  },
  data() {
    return {
      modal: false,
      text: "",
      btnss: [
        { language: "Introduce yourself", id: 1 },
        { language: "Your knowledge", id: 2 },
        { language: "Your values", id: 3 },
      ],
      value: null,
      selectedBtn: 0,
      options: [{ name: "Left" }, { name: "Right" }],
      myData: [],
    };
  },
  methods: {
    Play(src) {
      var audio = new Audio(src);
      audio.play();
    },
    async LoadData() {
      var firstData = this.$store.state.chatDetails[0];
      this.myData = [];
      this.myData.push(firstData);

      for (const element of this.$store.state.list) {
        var axios = require("axios");
        var config = {
          method: "get",
          url:
            "https://raaji.fastech.pk/api/ChatDetails/GetMessageByOption/" +
            element.id,
          headers: {
            Authorization:
              "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJNdWhhbW1hZCIsImVtYWlsIjoiYWxpMTIzQGdtYWlsLmNvbSIsImp0aSI6ImI0ODJjYjIyLTg0YjctNDZmMS05ODQ4LTdkODRjMjI2YjExMSIsImV4cCI6MTY5OTUzMDc1NCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.Emareb1_zFV8KHVLGv2GJABnDlkm5JONtWAYATT5OjM",
          },
        };

        var dataa = await axios(config);
        var myData = dataa.data.data;
        myData.chatDetail.options = element.option;
        this.myData.push(myData);

        // axios(config)
        //   .then((response) => {
        //     var myData = response.data.data;
        //     myData.chatDetail.options = element.option;
        //     this.myData.push(myData);
        //   })
        //   .catch((error) => {
        //     swal.fire({
        //       type: "error",
        //       title: "  " + error.message,
        //       text: "Something went wrong with the request, please try later.",
        //     });
        //   });
      }
    },
    modal_open() {
      this.modal = true;
    },
    selectOption(id) {
      this.$store.commit("setSelecedOption", id);
      this.$router.push("/botResponse");
    },
    editFirst(id) {
      this.$store.commit("setSelecedOption", null);
      this.$router.push("/botPage");
    },
    close() {
      this.modal = false;
    },
    createBot() {
      //   this.$router.push("/createBot");
    },
    async Publish() {
      var path = window.location.href.substring(
        window.location.href.lastIndexOf("/") + 1
      );

      console.log("CBID", this.$store.state.chatBotID);

      var config = {
        method: "get",
        url:
          "https://raaji.fastech.pk/api/ChatBots/" +
          this.$store.state.chatBotID,
        headers: {
          Authorization:
            "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJNdWhhbW1hZCIsImVtYWlsIjoiYWxpMTIzQGdtYWlsLmNvbSIsImp0aSI6ImI0ODJjYjIyLTg0YjctNDZmMS05ODQ4LTdkODRjMjI2YjExMSIsImV4cCI6MTY5OTUzMDc1NCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.Emareb1_zFV8KHVLGv2GJABnDlkm5JONtWAYATT5OjM",
        },
      };

      var response = await axios(config);
      console.log(response);
      this.$router.push("/bot/" + response.data.data.url);
    },
  },
  mounted() {
    if (this.$store.state.user == null) this.$router.push("/login");

    this.LoadData();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.btns {
  display: flex;

  height: auto;
  margin-top: 10px;
  margin-left: 45vw;
  margin-bottom: 20px;
}
.add_btn {
  background-color: white;
  padding: 14px 16px;
  border-radius: 12px;
  border: 2px solid #e68b00;
  background-color: #e68b00;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 100;
  color: #fff;
  width: 9rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add_btn:hover {
  background-color: #e68b00;
  color: #fff;
}

.myBorder {
  border: 2px solid red;
}

.btnContainerr {
  /* max-width: var(--maxWidth); */
  max-width: 960px;
  /* background-color: aqua; */
  padding-top: 2rem;

  margin: auto;
}

.myGrid {
  /* max-width: var(--maxWidth); */
  margin: 0 auto;
  max-width: 590px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10rem, 10rem;
  gap: 1rem;
  padding-top: 2rem;
  grid-template-areas: "card card ";
}

.myBg {
  background: url("../../assets/bg.png");
  height: 100vh;
  overflow: auto;
  /* margin-bottom: 4rem; */
}
.card {
  border: 8px solid #93117d;
  grid-area: card;
  position: relative;
  min-height: 43rem;
  height: auto;

  background: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border-radius: 15px;
}
.heading_iner {
  font-size: 22px;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #93117d;
}
/* .chat_sys {
  margin: 2rem 0;
} */
.bottom_class {
  position: absolute;
  bottom: 0;

  width: 500px;
}
.width_full {
  width: 100%;
  display: flex;
  justify-content: center;
}

.typo__label {
  margin-top: 1rem !important;
}
.main-container {
  padding: 1rem 1rem;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
}
.flexx {
  display: flex;
  /* justify-content: space-between; */
  margin: 2rem 0;
}

.img {
  display: flex;
  justify-content: center;
}
.img_inner {
  width: 360px;
  height: 180px;
  border-radius: 12px;
  object-fit: contain;
}
.modal_btn {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  background-color: #93117d;
  padding: 6px 11px;
  border-radius: 12px;
  /* border: 2px solid #93117d; */
  border-radius: 15px;
  font-weight: 500;
  margin: 14px 6px;
  font-size: 15px;
  font-weight: 100;
  color: white;
  /* width: 9rem; */
  cursor: pointer;
}
.unSelectedBtn {
  background-color: #fff;
  color: #93117d;
  border-color: #93117d;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  padding: 6px 11px;

  border-radius: 12px;
  /* border: 2px solid #93117d; */
  border-radius: 15px;
  font-weight: 500;
  margin: 14px 6px;
  font-size: 15px;
  font-weight: 100;

  /* width: 9rem; */
  cursor: pointer;
}
.modal_iner {
  margin: 12px 0;
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}
.model_outer {
  margin: 0 1rem;
}
</style>
