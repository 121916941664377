<template>
  <div
    style="      
      width: 100%;
      display: flex;
      align-items: flex-start
      justify-content: flex-start;
      flex-direction: column;
    "
  >
    <div class="user flexx">
      <div class="avatar">
        <img
          class="avatar_inner_user"
          src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
          alt=""
        />
      </div>
      <div
        class="admin_msg_outer"
        style="background-color: #cb6ce6; color: #fff; margin-left: 10px"
      >
        <p class="admin_msg">{{ text }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["text"],
};
</script>
<style scoped>
.avatar {
  align-items: flex-end;
  display: flex;
  position: relative;
  /* top: 1.2rem; */
}
.user {
  justify-content: flex-end;
}
.avatar_inner_user {
  height: 30px;
  width: 30px;
  border-radius: 50px;
}
.admin_msg_outer {
  background-color: rgb(221, 218, 218);

  padding: 0.5rem;
  border-radius: 15px;
}
.flexx {
  display: flex;
  /* justify-content: space-between; */
  margin: 2rem 0;
}
</style>
