<template>
  <div class="myBg">
    <div class="btnContainerr">
      <!-- <div class="btns">
        <button class="add_btn" @click="modal_open">Create New</button>
      </div> -->
    </div>
    <div class="myGrid">
      <div class="card">
        <!-- modal -->

        <div v-if="modal" class="modall" title="Add New Language">
          <!-- <row> -->
          <div class="widthh">
            <div class="class__add">
              <label class="addLanguages" for=""> Choose Language </label>
              <div class="cross" @click="close">
                <p class="para_cross">x</p>
              </div>
            </div>
            <div class="modal_iner">
              <!-- Dropdown -->
              <div>
                <button class="modal_btn">English</button>
                <button
                  class="modal_btn"
                  style="
                    background-color: #fff;
                    color: #93117d;
                    border-color: #fff;
                  "
                >
                  Urdu
                </button>
                <button
                  class="modal_btn"
                  style="
                    background-color: #fff;
                    color: #93117d;
                    border-color: #fff;
                  "
                >
                  Sindhi
                </button>
                <button
                  class="modal_btn"
                  style="
                    background-color: #fff;
                    color: #93117d;
                    border-color: #fff;
                  "
                >
                  Balochi
                </button>
                <button
                  class="modal_btn"
                  style="
                    background-color: #fff;
                    color: #93117d;
                    border-color: #fff;
                  "
                >
                  Pashto
                </button>
              </div>
            </div>
            <div class="" style="width: 100%">
              <button class="save_btn" @click="createBot()">Next</button>
            </div>
          </div>
          <!-- </row> -->
        </div>

        <!-- modal end -->

        <div class="main-container">
          <div class="heading">
            <h2 class="heading_iner">Englsih Chat Bot</h2>
          </div>
          <div class="line"></div>

          <div class="chat_sys">
            <userChat />

            <adminChat />
          </div>
          <!-- white hover placment -->
          <div class="bottom_class">
            <div class="auto_msg">
              <p class="auto_msg_iner">
                We help women all around the globe with our amazing CMS.
              </p>
            </div>
            <div class="width_full">
              <div class="btns">
                <button class="btn">Add</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import userChat from "../userChat/userChat.vue";
import adminChat from "../adminChat/adminChat.vue";
export default {
  components: {
    userChat,
    adminChat,
  },
  data() {
    return {
      modal: false,
      text: "",

      value: null,
      options: [{ name: "Left" }, { name: "Right" }],
    };
  },
  methods: {
    modal_open() {
      this.modal = true;
    },

    close() {
      this.modal = false;
    },
    createBot() {
      //   this.$router.push("/createBot");
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.btnContainerr {
  /* max-width: var(--maxWidth); */
  max-width: 960px;
  /* background-color: aqua; */
  padding-top: 2rem;

  margin: auto;
}

.myGrid {
  /* max-width: var(--maxWidth); */
  margin: 0 auto;
  max-width: 540px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10rem, 10rem;
  gap: 1rem;
  padding-top: 2rem;

  grid-template-areas: "card card ";
}

.myBg {
  height: 100vh;
}
.card {
  grid-area: card;
  position: relative;
  height: 37rem;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border-radius: 15px;
  /* max-width: 960px; */
  /* position: ; */
  /* margin: auto; */
}
.heading_iner {
  font-size: 22px;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  display: flex;
  color: gray;
}
/* .chat_sys {
  margin: 2rem 0;
} */
.bottom_class {
  position: absolute;
  bottom: 0;

  width: 500px;
}
.width_full {
  width: 100%;
  display: flex;
  justify-content: center;
}
.auto_msg {
  padding: 0.6rem;
  margin: 0.8rem 0;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.avatar {
  align-items: flex-end;
  display: flex;
}
.admin_msg_outer {
  background-color: rgb(221, 218, 218);

  padding: 1rem;
  border-radius: 15px;
}
.line {
  height: 2px;
  background-color: rgb(221, 218, 218);
}
.cross {
  background-color: #93117d;
  border-radius: 39px;
  padding: 9px;
  height: 28px;
}
.para_cross {
  color: #fff;
  margin-top: -10px;
  /* font-size: px; */
}
.user {
  justify-content: flex-end;
}
.avatar_inner_admin {
  height: 65px;
  width: 65px;
  border-radius: 50px;
}
.avatar_inner_user {
  height: 40px;
  width: 40px;
  border-radius: 50px;
}
.text_iner {
  padding: 10px 12px;
  border-radius: 12px;
  margin-bottom: 1rem;
  width: 400px;
}
.addLanguages {
  font-size: 24px;
  /* margin: 4rem 0; */
  color: #93117d;
}

.modall {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* box-shadow-padding:; */
  padding: 2rem;
  cursor: pointer !important;
  border-radius: 15px;
  width: fit-content;
  margin: auto;
  position: absolute;
  /* top: 50%; */
  bottom: -7.4rem;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  z-index: 1;
}
.typo__label {
  margin-top: 1rem !important;
}
.main-container {
  padding: 1rem 1rem;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
}
.flexx {
  display: flex;
  /* justify-content: space-between; */
  margin: 2rem 0;
}

.user {
}
.heading {
  /* margin-bottom: 2rem; */
  /* line-height: 0px; */
}

.btns {
  padding-bottom: 1rem;
  width: 460px;
  justify-content: center;
}
.btn {
  background-color: #93117d;
  padding: 14px 16px;
  border-radius: 12px;
  /* border: 2px solid #93117d; */
  border-radius: 20px;
  margin: 0 6px;
  font-size: 16px;
  font-weight: 100;
  color: #fff;
  width: -webkit-fill-available;
  cursor: pointer;
  margin: 0.6rem 0;
}
.add_btn {
  margin: 2rem 0;
  max-width: 960px;

  background-color: white;
  padding: 14px 16px;
  border-radius: 12px;
  border: 2px solid #93117d;
  border-radius: 10px;
  margin: 0 6px;
  font-size: 16px;
  font-weight: 100;
  color: #000;
  width: 9rem;
  cursor: pointer;
}
.btn:hover {
  background-color: #93117d;
  color: #fff;
}
.add_btn:hover {
  background-color: #93117d;
  color: #fff;
}
</style>
