<template>
  <div>
    <!-- <div class="myTermss">terms</div> -->
    <div id="navbar" class="myNavv sectionGap">
      <div id="fullNav"><Navbar /></div>
    </div>
    <!-- <img class="confetti" src="../../assets/confetti.svg" /> -->
    <div class="myGrid myBorder">
      <div class="card">hero banner</div>
      <div class="card">hero banner</div>
      <div class="card">hero banner</div>
      <div class="card">hero banner</div>
    </div>
    <div class="footer">footer</div>
  </div>
</template>

<script>
import Navbar from "../components/navbar/Navbar.vue";

export default {
  name: "HomeLayout",
  components: {
    Navbar,
  },
  data() {
    return {
      getTotalCartCount: 2,
    };
  },
};
</script>

<style scoped>
.maxWidth {
  max-width: var(--maxWidth);
  margin: 0 auto;
  padding: 0 var(--myPadding);
}

.card {
  grid-area: card;
  height: 10rem;
  background: turquoise;
}

.myBorder {
  border: 1px solid grey;
}
.sectionTwo {
}
.myTermss {
  background: lightcyan;
  grid-area: term;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  width: 100vw;
  height: var(--termsHeight);
}
.myNavv {
  background: lightseagreen;
  grid-area: nav;
  height: var(--navHeight);
  position: sticky;
  top: var(--termsHeight);
  max-width: var(--maxWidth);
  margin: 0 auto;
  transition: max-width 0.2s, height 0.2s;
  webkit-transition: max-width 0.2s, height 0.2s;
  -moz-transition: max-width 0.2s, height 0.2s;
  -ms-transition: max-width 0.2s, height 0.2s;
  -o-transition: max-width 0.2s, height 0.2s;
}
.fullWidth {
  max-width: 100vw;
  height: var(--navHeightSmall);
}
.myBanner {
  background: pink;
  grid-area: banner;
}
.myProperty {
  background: palevioletred;
  grid-area: property;
  padding: 0 var(--myPadding);
}
.previousWinner {
  background: pink;
  grid-area: previousWinner;
}
.myKeyring {
  background: lightblue;
  grid-area: myKeyring;
}
.myStory {
  background: lightseagreen;
  grid-area: myStory;
}
.myCharity {
  background: palevioletred;
  grid-area: myCharity;
}

.lookingToSell {
  background: lightseagreen;
  grid-area: lookingToSell;
}

.footer {
  background: lightseagreen;
  grid-area: footer;

  max-width: var(--maxWidth);
  margin: 0 auto;
  height: var(--footer);
}

.myGrid {
  max-width: var(--maxWidth);
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-column-gap: 1rem;

  grid-template-areas: "card";
}
.sectionThree {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, 3fr);
}

.sectionGap {
  margin-bottom: 2.5rem;
}
</style>
