<template>
  <div>
    <div class="myBg" ref="bg">
      <div class="myGrid">
        <div class="card" ref="mycard">
          <div class="main_card">
            <div class="header">
              <div class="icon">
                <!-- <i class="fas fa-arrow-left"></i> -->
              </div>
              <h2 class="heading_iner">{{ this.$store.state.botName }}</h2>
            </div>
            <div class="main_body">
              <div class="text_box">
                <div class="msg">
                  <h2 class="main_msg">Message:</h2>
                </div>
                <div class="input_outer">
                  <textarea
                    name=""
                    id=""
                    rows="1"
                    ref="textarea"
                    v-model="myObj.chatDetail.adminMessage"
                    class="input_one"
                    @focus="resize"
                    @keyup="resize"
                    placeholder="Type your message here..."
                  ></textarea>
                  <!-- <input
                    type="text"
                    class="input_one"
                    placeholder="I am from Karachi"
                  /> -->
                </div>
              </div>
              <div class="options">
                <div class="msg">
                  <h2 class="main_msg">Options:</h2>
                </div>
                <div class="input_fileds">
                  <div class="less_padding">
                    <div
                      v-for="item in chatOptions"
                      :key="item.id"
                      class="fields field_1"
                    >
                      <i class="fas fa-circle"></i>

                      <input
                        type="text"
                        class="input_two"
                        v-model="item.option"
                        :placeholder="item.option"
                      />
                      <i class="fas fa-times" @click="remove(item.id)"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="fields field_4"
                style="display: flex; justify-content: center"
              >
                <div class="btns">
                  <button class="btn" @click="addToArray">+</button>
                </div>
              </div>

              <!-- <div v-if="!url" class="img">
                <img
                  src="https://www.pulsecarshalton.co.uk/wp-content/uploads/2016/08/jk-placeholder-image.jpg"
                  alt=""
                  class="img_inner"
                />
              </div> -->

              <div id="preview">
                <i
                  v-if="this.myObj.chatDetail.media !== ''"
                  style="
                    margin-top: 0px;
                    margin-left: 10px;
                    position: absolute;
                    color: red;
                    cursor: pointer;
                  "
                  class="fa fa-trash fa-md text-danger"
                  @click="RemoveImage()"
                ></i>
                <img
                  class="img_inner"
                  :src="this.url"
                  @click="$refs.file.click()"
                />
              </div>

              <div class="btns_s">
                <input
                  hidden
                  ref="file"
                  type="file"
                  @change="selectFile"
                  accept="image/*"
                />

                <input
                  hidden
                  ref="fileAudio"
                  type="file"
                  accept="audio/*"
                  @change="selectAudio"
                />

                <button class="add_btn" @click="$refs.file.click()">
                  Upload media
                </button>
                <button class="add_btn" @click="$refs.fileAudio.click()">
                  <i
                    v-if="isBusy"
                    :class="this.spinnerIcon"
                    style="margin-right: 15px"
                  ></i
                  >Attach Audio
                </button>
              </div>
              <div :disabled="this.saving" class="save_btn" @click="Goto1()">
                <button class="save_btn_inner">
                  <i
                    v-if="saving"
                    :class="this.spinnerIcon"
                    style="margin-right: 15px"
                  ></i>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      saving: false,
      spinnerIcon: "fas fa-circle-notch fa-spin",
      isBusy: false,
      myObj: {
        chatDetail: {
          id: 0,
          adminMessage: "",
          userMessage: "",
          options: "",
          audio: "",
          media: "",
          chatBotId: this.$store.state.chatBotID,
          chatBot: null,
        },
        options: [],
      },
      shown: false,
      placeholder: "Type message here...",
      chatOptions: [
        {
          id: 1,
          dbID: 0,
          option: "Type message here",
        },
        {
          id: 2,
          dbID: 0,
          option: "Type message here",
        },
        {
          id: 3,
          dbID: 0,
          option: "Type message here",
        },
      ],
      url: "https://dk0pm9zdlq16s.cloudfront.net/5be0a5b2-5eaf-4090-8528-4be800d41de0.jpg",
    };
  },
  methods: {
    resize() {
      const { textarea } = this.$refs;
      const { mycard } = this.$refs;
      const { bg } = this.$refs;

      textarea.style.height = textarea.scrollHeight - 4 + "px";
      mycard.style.height = mycard.scrollHeight - 4 + "px";
      bg.style.height = bg.scrollHeight - 1 + "px";
    },
    show_field() {
      this.shown = true;
    },
    addToArray() {
      try {
        var lastID = this.chatOptions[this.chatOptions.length - 1].id;
        var obj = {
          id: lastID + 1,
          dbID: 0,
          option: "Type message here",
        };
        console.log(obj);
        this.chatOptions.push(obj);
      } catch (error) {
        var obj = {
          id: 1,
          dbID: 0,
          message: "Type message here",
        };
        this.chatOptions.push(obj);
        console.log(error);
      }
    },
    remove(id) {
      const index = this.chatOptions.findIndex((n) => n.id === id);
      this.chatOptions.splice(index, 1);
      console.log(index, this.chatOptions);
    },
    RemoveImage() {
      if (this.myObj.chatDetail.id == 0) {
        this.myObj.chatDetail.media = "";
        this.url =
          "https://dk0pm9zdlq16s.cloudfront.net/5be0a5b2-5eaf-4090-8528-4be800d41de0.jpg";
      } else {
        var axios = require("axios");
        var config = {
          method: "put",
          url:
            " https://raaji.fastech.pk/api/ChatDetails/ImageRemove/" +
            this.myObj.chatDetail.id,
          headers: {
            Authorization:
              "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJNdWhhbW1hZCIsImVtYWlsIjoiYWxpMTIzQGdtYWlsLmNvbSIsImp0aSI6ImI0ODJjYjIyLTg0YjctNDZmMS05ODQ4LTdkODRjMjI2YjExMSIsImV4cCI6MTY5OTUzMDc1NCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.Emareb1_zFV8KHVLGv2GJABnDlkm5JONtWAYATT5OjM",
          },
          data: this.myObj,
        };

        axios(config)
          .then((response) => {
            if (response.data.status == "success") {
              this.myObj.chatDetail.media = "";
              this.url =
                "https://dk0pm9zdlq16s.cloudfront.net/5be0a5b2-5eaf-4090-8528-4be800d41de0.jpg";
              console.log(response.data);
            }

            // this.tableData  =  response.data.data;
            // this.images  =  response.data.data.images;

            //  console.log(response.data.data);
          })
          .catch(function (error) {
            this.isActive = false;

            //  swal.fire({
            //           type: 'error',
            //            title: '  ' + error.message,
            //           text: 'Something went wrong with the connection, Please try again later!',
            //         })
          });
      }
    },
    selectFile() {
      this.file = this.$refs.file.files[0];

      //Upload cover
      if (this.file !== "") {
        let formData = new FormData();
        formData.append("file", this.file);
        console.log(this.file);
        this.url =
          "https://s3.amazonaws.com/msc-media-linux-production/5e0ea029945d6.gif";
        axios
          .post("https://upload.appick.io", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            console.log(e.data);
            // this.file = undefined;
            var fn = e.data.myresp[0].path;

            this.url = fn;
            this.myObj.chatDetail.media = fn;
          })
          .catch((e) => {
            this.url =
              "https://www.pulsecarshalton.co.uk/wp-content/uploads/2016/08/jk-placeholder-image.jpg";
          });
      }
    },

    selectAudio() {
      this.isBusy = true;
      this.file = this.$refs.fileAudio.files[0];

      //Upload cover
      if (this.file !== "") {
        let formData = new FormData();
        formData.append("file", this.file);
        console.log(this.file);

        axios
          .post("https://upload.appick.io", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            console.log(e.data);
            this.isBusy = false;
            // this.file = undefined;
            var fn = e.data.myresp[0].path;
            this.myObj.chatDetail.audio = fn;

            var audio = new Audio(fn);
            audio.play();
          })
          .catch((e) => {
            this.isBusy = false;
          });
      }
    },

    Goto1() {
      this.saving = true;
      try {
        this.myObj.options = [];
        this.chatOptions.forEach((element) => {
          if (element.option != "Type message here") {
            console.log(element.option);
            this.myObj.options.push({
              id: element.dbID,
              option: element.option,
            });
          } else {
            console.log("else", element.option);
          }
        });

        if (this.myObj.chatDetail.adminMessage !== "") {
          if (this.myObj.options.length == 0) {
            this.saving = false;
            this.$swal.fire({
              type: "error",
              icon: "error",
              title: "Error",
              text: "Please add few options",
            });
            return;
          }

          this.myObj.chatDetail.chatBotId = this.$store.state.chatBotID;

          var config = {
            method: "post",
            url: "https://raaji.fastech.pk/api/ChatDetails/CreateChatDetails",
            headers: {
              Authorization:
                "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJNdWhhbW1hZCIsImVtYWlsIjoiYWxpMTIzQGdtYWlsLmNvbSIsImp0aSI6ImI0ODJjYjIyLTg0YjctNDZmMS05ODQ4LTdkODRjMjI2YjExMSIsImV4cCI6MTY5OTUzMDc1NCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.Emareb1_zFV8KHVLGv2GJABnDlkm5JONtWAYATT5OjM",
            },
            data: this.myObj,
          };

          if (this.myObj.chatDetail.id > 0) {
            //Edit
            config = {
              method: "put",
              url: "https://raaji.fastech.pk/api/ChatDetails/UpdateMessage",
              headers: {
                Authorization:
                  "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJNdWhhbW1hZCIsImVtYWlsIjoiYWxpMTIzQGdtYWlsLmNvbSIsImp0aSI6ImI0ODJjYjIyLTg0YjctNDZmMS05ODQ4LTdkODRjMjI2YjExMSIsImV4cCI6MTY5OTUzMDc1NCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.Emareb1_zFV8KHVLGv2GJABnDlkm5JONtWAYATT5OjM",
              },
              data: this.myObj,
            };
          }

          axios(config)
            .then((response) => {
              if (response.data.status == "success") {
                console.log(response.data);
                this.$store.commit("clearChatDetails", null);
                var myData = response.data.data;
                myData.chatDetail.options = "";
                this.$store.commit("setChatDetails", myData);
                this.$router.push("/create/" + this.$store.state.botName);
              }
            })
            .catch(function (error) {
              console.error(error);
              this.saving = false;
              this.$swal.fire({
                type: "error",
                icon: "error",
                title: "  " + error.message,
                text: "Something went wrong with the connection, Please try again later!",
              });
            });
        } else {
          this.saving = false;
          this.$swal.fire({
            type: "error",
            icon: "error",
            title: "Fill details",
            text: "Please write a message",
          });
        }
      } catch (error) {
        console.log(error.message);
      }
    },
  },
  created() {
    if (this.$store.state.chatDetails.length > 0) {
      //1st message
      this.myObj = this.$store.state.chatDetails[0];

      console.log(this.myObj);

      if (this.myObj.chatDetail.media != "")
        this.url = this.myObj.chatDetail.media;

      this.chatOptions = [];
      this.myObj.options.forEach((element) => {
        this.chatOptions.push({
          id: element.id,
          dbID: element.id,
          option: element.option,
        });
      });
    }
  },
  mounted() {
    this.resize();
    if (this.$store.state.user == null) this.$router.push("/login");
  },
};
</script>
<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.options {
  max-height: 11rem;
  overflow-x: auto;
}
.myBorder {
  border: 2px solid red;
}
.myBg {
  background: url("../../assets/bg.png");
  height: 100vh;
  /* margin-bottom: 4rem; */
}

.input_fileds {
  overflow-y: hidden;
  overflow-x: hidden;
  height: auto;
  scrollbar-width: none;
}
.input_fileds::-webkit-scrollbar {
  width: 0px;
}
.myGrid {
  /* max-width: var(--maxWidth); */
  margin: 0 auto;
  max-width: 540px;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10rem 10rem;
  gap: 1rem;
  padding-top: 2rem;
  grid-template-areas: "card card";
}
.main_card {
  padding: 1rem 2rem;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
}

.card {
  grid-area: card;
  position: relative;
  height: 100%;
  max-height: 200rem;
  min-height: 35rem;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border-radius: 15px;
}
.header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-left: -8rem;
}
.heading_iner {
  font-size: 22px;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #93117d;
}
.input_one {
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
  width: 100%;
  font-family: "Roboto", sans-serif;
  padding: 0.2rem 0.2rem;
  font-size: 16px;
  resize: none;
}
.input_two {
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
  width: 87%;
  font-family: "Roboto", sans-serif;
  padding: 0.1rem 0;
  font-size: 16px;
}
.fields {
  padding: 0.6rem 0;
  display: flex;
  align-items: center;
}
.fa-circle {
  font-size: 10px;
  opacity: 0.5;
  margin: 0 0.6rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.fa-times {
  margin-left: 1rem;
}
.btns {
  /* padding: 0 0.6rem; */
  align-items: center;
  margin-right: 0rem;
  margin-top: -1rem;
  display: flex;
  justify-content: center;
}
.btn {
  background-color: #93117d;
  border-radius: 12px;
  border-radius: 50px;
  border-color: #93117d;
  height: 2rem;
  width: 2rem;
  margin: 0 6px;
  font-size: 26px;
  font-weight: 100;
  color: #fff;
  cursor: pointer;
  margin: 0.6rem 0;
}
.less_padding {
  padding-right: 0.4rem;
}
/* .img {
  display: flex;
  justify-content: center;
} */
.img_inner {
  width: 260px;
  height: 160px;
  border-radius: 12px;
  object-fit: contain;
}
.btns_s {
  padding-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  /* max-width: 960px; */
}

.add_btn {
  margin: 2rem 0;
  /* max-width: 960px; */
  background-color: white;
  padding: 7px 16px;
  border-radius: 12px;
  border: 2px solid #93117d;
  border-radius: 25px;
  margin: 0 6px;
  font-size: 18px;
  font-weight: 100;
  color: #93117d;
  width: 12.6rem;
  cursor: pointer;
}
.save_btn {
  padding-top: 0.5rem;
}
.save_btn_inner {
  margin: 2rem 0;
  /* max-width: 960px; */
  background-color: #93117d;
  padding: 7px 16px;
  border-radius: 12px;
  border: 2px solid #93117d;
  border-radius: 25px;
  margin: 0 6px;
  font-size: 18px;
  font-weight: 100;
  color: #fff;
  width: 98%;
  cursor: pointer;
}
</style>
