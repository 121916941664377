<template>
  <div class="myBg">
    <div class="btnContainerr">
      <div v-bind:class="{ overlay: isActive }">
        <div v-if="modal" class="modall">
          <!-- <row> -->
          <div class="widthh">
            <div class="class__add">
              <label class="addLanguages" for=""> Add Languages </label>
              <div class="cross">
                <p class="para_cross" @click="close">x</p>
              </div>
            </div>
            <div class="modal_iner">
              <div class="text_outr">
                <input
                  class="text_iner"
                  v-model="myObj.name"
                  type="text"
                  placeholder="Enter Language"
                />
              </div>

              <!-- Dropdown -->
              <div style="display: flex">
                <div v-for="btns in btnss" :key="btns">
                  <button
                    :class="selectedBtn == btns ? 'modal_btn' : 'unSelectedBtn'"
                    @click="selectedButton(btns)"
                  >
                    {{ btns }}
                  </button>
                </div>
                <!-- <button class="modal_btn">Right</button>

            <button
              class="modal_btn"
              style="background-color: #fff; color: #93117d; border-color: #fff"
              b
            >
              Left
            </button> -->
              </div>
            </div>
            <div class="" style="width: 100%">
              <button class="save_btn" @click="Save()">Save</button>
            </div>
          </div>
          <!-- </row> -->
        </div>
      </div>

      <div class="addBtn">
        <div class="btns">
          <button class="btn" @click="modal_open">Create New</button>
        </div>
      </div>
    </div>
    <div class="myGrid">
      <div>
        <div
          v-for="lang in tableData"
          :key="lang.id"
          style="margin-top: 10px"
          class="card"
        >
          <div class="main-container">
            <div class="heading">
              <h2 class="heading_iner">{{ lang.name }}</h2>
            </div>
            <div class="flexx">
              <div class="side">
                <p class="paraa">
                  <br />
                  {{ lang.sequence }}
                </p>
              </div>
              <div class="btns">
                <button class="btn" @click="modal_Edit(lang)">Edit</button>
                <button class="btn" @click="modal_Delete(lang)">Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Cards from "../components/cards_page/Cards.vue";
import AddBtn from "../components/addBtn/AddBtn.vue";
import { BRow, BCol } from "bootstrap-vue";

export default {
  name: "Home",
  components: {
    Cards,
    AddBtn,
    BRow,
    BCol,
  },
  created() {
    this.LoadData();
    // this.$router.go();
  },
  data() {
    return {
      isActive: false,
      selectedBtn: "",
      btnss: ["Right", "Left"],
      modal: false,
      text: "",
      myObj: {
        id: 0,
        name: "",
        sequence: "",
      },
      value: null,
      options: [{ name: "Left" }, { name: "Right" }],
      tableData: [],
      getTotalCartCount: 2,
    };
  },
  methods: {
    modal_Edit(row) {
      this.modal = true;
      this.myObj.id = row.id;
      this.myObj.name = row.name;
      this.myObj.sequence = row.sequence;
      this.isActive = true;
      this.selectedBtn = row.sequence;
      console.log(this.myObj);
    },
    modal_Delete(row) {
      //  return console.log(row);
      //  this.$swal('Hello Vue world!!!');

      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          var axios = require("axios");

          var config = {
            method: "delete",
            url: `https://raaji.fastech.pk/api/Languages/${row.id}`,
            headers: {
              Authorization:
                "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJNdWhhbW1hZCIsImVtYWlsIjoiYWxpMTIzQGdtYWlsLmNvbSIsImp0aSI6ImI0ODJjYjIyLTg0YjctNDZmMS05ODQ4LTdkODRjMjI2YjExMSIsImV4cCI6MTY5OTUzMDc1NCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.Emareb1_zFV8KHVLGv2GJABnDlkm5JONtWAYATT5OjM",
            },
          };

          axios(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === "success") {
                // this.$notify({
                //   verticalAlign: "bottom",
                //   horizontalAlign: "center",
                //   message:
                //     'Saved! <br />Inquiry of " ' +   " "  + row.name +  " " + '" removed.',
                //   type: "danger",
                //   icon: "fa fa-check",
                //   timeout: 0,
                // });
                this.LoadData();
              } else if (response.data.status === "notallowed") {
                this.$swal({
                  title: "Not Allowed !",
                  text: "You cannot delete this property it has relative data!",
                  type: "warning",
                  showCancelButton: false,
                  confirmButtonClass: "btn btn-primary btn-fill",
                  confirmButtonText: "Ok",
                  buttonsStyling: false,
                });

                //   this.$notify({
                //   verticalAlign: "bottom",
                //   horizontalAlign: "center",
                //   message:
                //     'You cannot delete this property it has relative data.!',
                //   type: "warning",
                //   icon: "fa fa-warning",
                //   timeout: this.$store.state.timeout,
                // });
              }
            })
            .catch(function (error) {
              this.$swal.fire({
                type: "error",
                title: "  " + error.message,
                text: "Something went wrong with the connection, Please try again later!",
              });
            });
        }
      });
    },
    Save() {
      //return console.log(this.myObj);
      if (this.myObj.name == "" || this.myObj.sequence == "") {
        console.log("Helloo");
        this.$notify({
          group: "foo",
          title: "Important message",
          text: "Hello user! This is a notification!",
        });
      } else if (this.myObj.id == 0) {
        console.log(this.myObj);
        var axios = require("axios");
        var config = {
          method: "post",
          url: "https://raaji.fastech.pk/api/Languages",
          headers: {
            Authorization:
              "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJNdWhhbW1hZCIsImVtYWlsIjoiYWxpMTIzQGdtYWlsLmNvbSIsImp0aSI6ImI0ODJjYjIyLTg0YjctNDZmMS05ODQ4LTdkODRjMjI2YjExMSIsImV4cCI6MTY5OTUzMDc1NCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.Emareb1_zFV8KHVLGv2GJABnDlkm5JONtWAYATT5OjM",
          },
          data: this.myObj,
        };

        axios(config)
          .then((response) => {
            if (response.data.status == "success") {
              console.log(response.data);
              this.LoadData();
              this.modal = false;
              this.isActive = false;
            }

            // this.tableData  =  response.data.data;
            // this.images  =  response.data.data.images;

            //  console.log(response.data.data);
          })
          .catch(function (error) {
            this.isActive = false;
            this.modal = false;
            swal.fire({
              type: "error",
              title: "  " + error.message,
              text: "Something went wrong with the connection, Please try again later!",
            });
          });
      } else if (this.myObj.id !== 0) {
        // return console.log(this.myObj);
        var axios = require("axios");
        var config = {
          method: "put",
          url: "https://raaji.fastech.pk/api/Languages/" + this.myObj.id,
          headers: {
            Authorization:
              "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJNdWhhbW1hZCIsImVtYWlsIjoiYWxpMTIzQGdtYWlsLmNvbSIsImp0aSI6ImI0ODJjYjIyLTg0YjctNDZmMS05ODQ4LTdkODRjMjI2YjExMSIsImV4cCI6MTY5OTUzMDc1NCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.Emareb1_zFV8KHVLGv2GJABnDlkm5JONtWAYATT5OjM",
          },
          data: this.myObj,
        };

        axios(config)
          .then((response) => {
            if (response.data.status == "success") {
              console.log(response.data);
              this.LoadData();
              this.modal = false;
              this.isActive = false;
            }

            // this.tableData  =  response.data.data;
            // this.images  =  response.data.data.images;

            //  console.log(response.data.data);
          })
          .catch(function (error) {
            this.isActive = false;
            this.modal = false;

            //  swal.fire({
            //           type: 'error',
            //            title: '  ' + error.message,
            //           text: 'Something went wrong with the connection, Please try again later!',
            //         })
          });
      }
    },
    selectedButton(id) {
      this.selectedBtn = id;
      this.myObj.sequence = this.selectedBtn;
      console.log(this.selectedBtn);
    },
    modal_open() {
      this.modal = true;
      this.myObj.id = 0;
      this.myObj.name = "";
      this.myObj.sequence = "";
      this.isActive = true;
    },

    close() {
      this.modal = false;
      this.isActive = false;
    },
    LoadData() {
      var axios = require("axios");
      var config = {
        method: "get",
        url: "https://raaji.fastech.pk/api/Languages",
        headers: {
          Authorization:
            "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJNdWhhbW1hZCIsImVtYWlsIjoiYWxpMTIzQGdtYWlsLmNvbSIsImp0aSI6ImI0ODJjYjIyLTg0YjctNDZmMS05ODQ4LTdkODRjMjI2YjExMSIsImV4cCI6MTY5OTUzMDc1NCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.Emareb1_zFV8KHVLGv2GJABnDlkm5JONtWAYATT5OjM",
        },
      };

      axios(config)
        .then((response) => {
          console.log(response.data);

          this.tableData = response.data.data;
          // this.images  =  response.data.data.images;
          // this.$notify({
          //   group: "foo",
          //   title: "Important message",
          //   text: "Hello user! This is a notification!",
          // });
          // console.log(response.data.data);
        })
        .catch(function (error) {
          swal.fire({
            type: "error",
            title: "  " + error.message,
            text: "Something went wrong with the connection, Please try again later!",
          });
        });
    },
  },
  mounted() {
    if (this.$store.state.user == null) this.$router.push("/login");
  },
};
</script>
<style src="vue-notifyjs/themes/default.css"></style>
<style scoped>
.overlay {
  position: fixed;
  display: block;

  /* color with alpha channel */
  background-color: rgba(0, 0, 0, 0.7); /* 0.7 = 70% opacity */

  /* stretch to screen edges */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.card {
  grid-area: card;
  height: 9rem;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border-radius: 15px;
  /* max-width: 960px; */
  /* position: ; */
  /* margin-top: 2rem; */
}
.heading_iner {
  font-size: 32px;
  /* line-height: 0px; */
  font-weight: 600;
  color: #93117d;
}
.cross {
  background-color: #93117d;
  border-radius: 39px;
  padding: 9px;
  height: 28px;
}
.modal_iner {
  display: flex;
  justify-content: space-between;
}
.para_cross {
  color: #fff;
  margin-top: -10px;
  /* font-size: px; */
}
.unSelectedBtn {
  background-color: #fff;
  color: #93117d;
  border-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  padding: 12px 16px;
  border-radius: 12px;
  /* border: 2px solid #93117d; */
  border-radius: 10px;
  font-weight: 500;
  margin: 0 6px;
  font-size: 16px;
  font-weight: 100;

  width: 9rem;
  cursor: pointer;
}
.text_iner {
  padding: 10px 12px;
  border-radius: 12px;
  margin-bottom: 1rem;
  width: 500px;
}
.addLanguages {
  font-size: 24px;
  /* margin: 4rem 0; */
  color: #93117d;
}

.class__add {
  margin-bottom: 1.6rem;
  display: flex;
  justify-content: space-between;
}
.save_btn {
  background-color: #93117d;
  padding: 12px 16px;
  border-radius: 12px;
  /* border: 2px solid #93117d; */
  border-radius: 10px;
  margin: auto;
  display: block;
  font-size: 16px;
  font-weight: 100;
  color: white;
  width: 19rem;
  cursor: pointer;
}
.modal_btn {
  background-color: #93117d;
  padding: 12px 16px;
  border-radius: 12px;
  /* border: 2px solid #93117d; */
  border-radius: 10px;
  font-weight: 500;
  margin: 0 6px;
  font-size: 16px;
  font-weight: 100;
  color: white;
  width: 9.8rem;
  cursor: pointer;
}
.widthh {
  width: 880px;
  /* bottom: 0; */
}
.paraa {
  font-size: 17px;
  color: gray;
  line-height: 1.4rem;
}
.modall {
  cursor: pointer !important;
}
.modall {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* box-shadow-padding:; */
  padding: 2rem;
  border-radius: 15px;
  width: fit-content;
  margin: auto;
  position: absolute;
  /* top: 50%; */
  bottom: -6.8rem;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  z-index: 1;
}
.typo__label {
  margin-top: 1rem !important;
}
.main-container {
  padding: 1rem 3rem;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
}
.flexx {
  display: flex;
  justify-content: space-between;
  margin-top: -2rem;
}
.heading {
  margin-bottom: 2rem;
  /* line-height: 0px; */
}

.btn {
  background-color: white;
  padding: 14px 16px;
  border-radius: 12px;
  border: 2px solid #93117d;
  border-radius: 10px;
  margin: 0 6px;
  font-size: 16px;
  font-weight: 100;
  color: #000;
  width: 6rem;
  cursor: pointer;
}
.btn:hover {
  background-color: #93117d;
  color: #fff;
}

.btnContainerr {
  /* max-width: var(--maxWidth); */
  max-width: 960px;
  /* background-color: aqua; */
  margin: auto;
}
.btns {
  padding-top: 1rem;
}
.text_iner {
  padding: 10px 12px;
  border-radius: 12px;
  margin-bottom: 1rem;
  width: 500px;
}
.btn {
  background-color: white;
  padding: 16px 26px;
  border-radius: 12px;
  border: 2px solid #93117d;
  border-radius: 10px;
  margin: 0 4px;
  color: rgb(38, 30, 30);
  font-size: 16px;
  font-weight: 100;

  width: 10rem;
  cursor: pointer;
}

.unSelectedBtn {
  background-color: #fff;
  color: #93117d;
  border-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  padding: 12px 16px;
  border-radius: 12px;
  /* border: 2px solid #93117d; */
  border-radius: 10px;
  font-weight: 500;
  margin: 0 6px;
  font-size: 16px;
  font-weight: 100;

  width: 9rem;
  cursor: pointer;
}
.btn:hover {
  background-color: #93117d;
  color: #fff;
}
.addLanguages {
  font-size: 24px;
  /* margin: 4rem 0; */
  color: #93117d;
}
/* .modal_iner {
  display: flex;
  justify-content: space-between;
} */
.class__add {
  margin-bottom: 1.6rem;
}
.save_btn {
  background-color: #93117d;
  padding: 12px 16px;
  border-radius: 12px;
  /* border: 2px solid #93117d; */
  border-radius: 10px;
  margin: auto;
  display: block;
  font-size: 16px;
  font-weight: 100;
  color: white;
  width: 19rem;
  cursor: pointer;
}
.modal_btn {
  background-color: #93117d;
  padding: 12px 16px;
  border-radius: 12px;
  /* border: 2px solid #93117d; */
  border-radius: 10px;
  font-weight: 500;
  margin: 0 6px;
  font-size: 16px;
  font-weight: 100;
  color: white;
  width: 10rem;
  cursor: pointer;
}
.modall {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* box-shadow-padding:; */
  padding: 2rem;
  border-radius: 15px;
  width: fit-content;
  margin: auto;
  position: absolute;
  /* top: 50%; */
  bottom: -6.8rem;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  z-index: 1;
}
.typo__label {
  margin-top: 1rem !important;
}

.myBg {
  background: url("../assets/bg.png");
  height: 100%;
  min-height:100vh ;
  /* margin-bottom: 4rem; */
}

.maxWidth {
  max-width: var(--maxWidth);
  margin: 0 auto;
  padding: 0 var(--myPadding);
}

.card {
  grid-area: card;
  height: 9rem;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border-radius: 15px;
  /* max-width: 960px; */
  /* position: ; */
  /* margin-top: 2rem; */
}

.myBorder {
  /* border: 1px solid grey; */
}
.sectionTwo {
  grid-area: sectionThree;
}
.myTermss {
  background: lightcyan;
  grid-area: term;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  width: 100vw;
  height: var(--termsHeight);
}
.myNavv {
  background: lightseagreen;
  grid-area: nav;
  height: var(--navHeight);
  position: sticky;
  max-width: var(--maxWidth);
  margin: 0 auto;
  transition: max-width 0.2s, height 0.2s;
  webkit-transition: max-width 0.2s, height 0.2s;
  -moz-transition: max-width 0.2s, height 0.2s;
  -ms-transition: max-width 0.2s, height 0.2s;
  -o-transition: max-width 0.2s, height 0.2s;
}
.fullWidth {
  max-width: 100vw;
  height: var(--navHeightSmall);
}
.myBanner {
  background: pink;
  grid-area: banner;
}
.myProperty {
  background: palevioletred;
  grid-area: property;
  padding: 0 var(--myPadding);
}
.previousWinner {
  background: pink;
  grid-area: previousWinner;
}
.myKeyring {
  background: lightblue;
  grid-area: myKeyring;
}
.myStory {
  background: lightseagreen;
  grid-area: myStory;
}
.myCharity {
  background: palevioletred;
  grid-area: myCharity;
}

.lookingToSell {
  background: lightseagreen;
  grid-area: lookingToSell;
}

.footer {
  background: lightseagreen;
  grid-area: footer;

  max-width: var(--maxWidth);
  margin: 0 auto;
  height: var(--footer);
}

.newContainer {
  max-width: var(--maxWidth);
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  background: red;
}

.myGrid {
  /* max-width: var(--maxWidth); */
  margin: 0 auto;
  max-width: 960px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10rem, 10rem;
  gap: 1rem;
  padding-top: 2rem;

  grid-template-areas: "card card ";
}
/* @media screen and () {
  
} */
.sectionThree {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, 3fr);
}

.sectionGap {
  margin-bottom: 2.5rem;
}
</style>
