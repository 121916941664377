<template>
  <div class="btnContainerr">
     <div v-bind:class="{ overlay: isActive}" >
       <div v-if="modal" class="modall">
      <!-- <row> -->
      <div class="widthh">
        <div class="class__add">
          <label class="addLanguages" for=""> Add Languages </label>
           <div class="cross"><p class="para_cross" @click="close">x</p></div>
        </div>
        <div class="modal_iner">
          <div class="text_outr">
            <input
              class="text_iner"
              v-model="myObj.name"
              type="text"
              placeholder="Enter Language"
            />
          </div>

          <!-- Dropdown -->
          <div>
             <div v-for="btns in btnss" :key="btns">
            <button
              :class="selectedBtn == btns ? 'modal_btn' : 'unSelectedBtn'"
              @click="selectedButton(btns)"
            >
              {{ btns }}
            </button>
          </div>
            <!-- <button class="modal_btn">Right</button>

            <button
              class="modal_btn"
              style="background-color: #fff; color: #93117d; border-color: #fff"
              b
            >
              Left
            </button> -->
          </div>
        </div>
        <div class="" style="width: 100%">
          <button class="save_btn" @click="Save()">Save</button>
        </div>
      </div>
      <!-- </row> -->
    </div>
     </div>
    

    <div class="addBtn">
      <div class="btns">
        <button class="btn" @click="modal_open">Create New</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    // Multiselect,
  },
  data() {
    return {
      selectedBtn:'',
            btnss: [
          "Right",
       "left",
      ],
      modal: false,
      text: "",
      myObj:
      {
        id:0,
        name:"",
        sequence:"",
      }
,      language: [
        { title: "Urdu", date: "12 Sep, 2021", side: "Right" },
        { title: "English", date: "12 Sep, 2021", side: "Right Side" },
        { title: "Arabic", date: "12 Sep, 2021", side: "Right Side" },
      ],
      value: null,
      options: [{ name: "Left" }, { name: "Right" }],
    };
  },
  methods: {
    Save()
    {
     //return console.log(this.myObj);
      if(this.myObj.name == "" || this.myObj.sequence == ""  )
      {
        return 0;
      }
      else if(this.myObj.id == 0)
      {
        console.log(this.myObj);
var axios = require('axios');
  var config = {
    method: 'post',
    url: 'https://raaji.fastech.pk/api/Languages',
    headers: {
        Authorization: 'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJNdWhhbW1hZCIsImVtYWlsIjoiYWxpMTIzQGdtYWlsLmNvbSIsImp0aSI6ImI0ODJjYjIyLTg0YjctNDZmMS05ODQ4LTdkODRjMjI2YjExMSIsImV4cCI6MTY5OTUzMDc1NCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.Emareb1_zFV8KHVLGv2GJABnDlkm5JONtWAYATT5OjM' 
    },
    data:this.myObj
  };

  axios(config)
  .then( (response) => {
    if(response.data.status == "success")
    {
    console.log(response.data);
    this.LoadData();
    this.modal = false;
    }

    // this.tableData  =  response.data.data;
    // this.images  =  response.data.data.images;

   //  console.log(response.data.data);

 })
  .catch(function (error) {
     swal.fire({
              type: 'error',
               title: '  ' + error.message,
              text: 'Something went wrong with the connection, Please try again later!',
            })
  });
      }
      else if(this.myObj.id !== 0)
      {
// return console.log(this.myObj);
var axios = require('axios');
  var config = {
    method: 'put',
    url: 'https://raaji.fastech.pk/api/Users/'+ this.myObj.Id,
    headers: {
        Authorization: 'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJNdWhhbW1hZCIsImVtYWlsIjoiYWxpMTIzQGdtYWlsLmNvbSIsImp0aSI6ImI0ODJjYjIyLTg0YjctNDZmMS05ODQ4LTdkODRjMjI2YjExMSIsImV4cCI6MTY5OTUzMDc1NCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.Emareb1_zFV8KHVLGv2GJABnDlkm5JONtWAYATT5OjM' 
    },
    data:this.myObj
  };

  axios(config)
  .then( (response) => {
    if(response.data.status == "success")
    {
    console.log(response.data);
    this.LoadData();
    this.modal = false;
    }

    // this.tableData  =  response.data.data;
    // this.images  =  response.data.data.images;

   //  console.log(response.data.data);

 })
  .catch(function (error) {
    //  swal.fire({
    //           type: 'error',
    //            title: '  ' + error.message,
    //           text: 'Something went wrong with the connection, Please try again later!',
    //         })
  });

      }

    },
     selectedButton(id) {
      this.selectedBtn = id;
      this.myObj.sequence = this.selectedBtn;
      console.log(this.selectedBtn);
    },
    modal_open() {
      this.modal = true;
      this.myObj.id = 0;
      this.myObj.name = "";
      this.myObj.sequence = "";

    },

    close() {
      this.modal = false;
    },
  },
};
</script>
<style scoped>
.btnContainerr {
  /* max-width: var(--maxWidth); */
  max-width: 960px;
  /* background-color: aqua; */
  margin: auto;
}
.btns {
  padding-top: 3rem;
}
.text_iner {
  padding: 10px 12px;
  border-radius: 12px;
  margin-bottom: 1rem;
  width: 500px;
}
.btn {
  background-color: white;
  padding: 16px 26px;
  border-radius: 12px;
  border: 2px solid #93117d;
  border-radius: 10px;
  margin: 0 4px;
  color: rgb(38, 30, 30);
  font-size: 16px;
  font-weight: 100;

  width: 10rem;
  cursor: pointer;
}

.unSelectedBtn {
  background-color: #fff;
  color: #93117d;
  border-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  padding: 12px 16px;
  border-radius: 12px;
  /* border: 2px solid #93117d; */
  border-radius: 10px;
  font-weight: 500;
  margin: 0 6px;
  font-size: 16px;
  font-weight: 100;

  width: 9rem;
  cursor: pointer;
}
.btn:hover {
  background-color: #93117d;
  color: #fff;
}
.addLanguages {
  font-size: 24px;
  /* margin: 4rem 0; */
  color: #93117d;
}
.modal_iner {
  display: flex;
  justify-content: space-between;
}
.class__add {
  margin-bottom: 1.6rem;
}
.save_btn {
  background-color: #93117d;
  padding: 12px 16px;
  border-radius: 12px;
  /* border: 2px solid #93117d; */
  border-radius: 10px;
  margin: auto;
  display: block;
  font-size: 16px;
  font-weight: 100;
  color: white;
  width: 19rem;
  cursor: pointer;
}
.modal_btn {
  background-color: #93117d;
  padding: 12px 16px;
  border-radius: 12px;
  /* border: 2px solid #93117d; */
  border-radius: 10px;
  font-weight: 500;
  margin: 0 6px;
  font-size: 16px;
  font-weight: 100;
  color: white;
  width: 10rem;
  cursor: pointer;
}
.modall {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* box-shadow-padding:; */
  padding: 2rem;
  border-radius: 15px;
  width: fit-content;
  margin: auto;
  position: absolute;
  /* top: 50%; */
  bottom: -6.8rem;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  z-index: 1;
}
.typo__label {
  margin-top: 1rem !important;
}
</style>
