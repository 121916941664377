<template>
  <div class="myBg">
    <div class="btnContainerr">
      <div class="btns">
        <button class="add_btn" @click="modal_open">Create New</button>
      </div>
    </div>
    <div class="myGrid">
      <div>
        <div v-bind:class="{ overlay: isActive }">
          <!-- modal -->
          <div v-if="modal" class="modall" title="Add New Language">
            <!-- <row> -->
            <div class="widthh">
              <div class="class__add">
                <label class="addLanguages" for=""> Choose Language </label>
                <div class="cross" @click="close">
                  <p class="para_cross">x</p>
                </div>
              </div>
              <div class="modal_iner">
                <!-- Dropdown -->
                <div v-for="btns in languages" :key="btns.id">
                  <button
                    :class="
                      selectedBtn == btns.id ? 'modal_btn' : 'unSelectedBtn'
                    "
                    @click="selectedButton(btns.id)"
                  >
                    {{ btns.name }}
                  </button>
                </div>
              </div>
              <div class="" style="width: 100%">
                <button class="save_btn" @click="createBot()">Next</button>
              </div>
            </div>
            <!-- </row> -->
          </div>
          <!-- modal end -->
        </div>
        <div
          v-for="u in botData"
          :key="u.id"
          style="margin-top: 10px"
          class="card"
        >
          <div class="main-container">
            <div class="heading">
              <h2 class="heading_iner">{{ u.title }}</h2>
            </div>
            <div class="flexx">
              <div class="side">
                <p class="paraa">
                  https://beta.auratraaj.co/#/bot/{{ u.url }}<br />
                  Created on: {{ u.createdOn.split("T")[0] }}
                </p>
              </div>
              <div class="">
                <button class="btn" @click="Edit(u.url, u.id, u.title)">
                  Edit
                </button>
                <button class="btn" @click="modal_Delete(u.id)">Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  created() {
    this.LoadData();
    this.LoadLang();
  },
  data() {
    return {
      isActive: false,
      languages: [],
      botData: [],
      modal: false,
      text: "",
      btnss: ["English", "Urdu", "Sindhi", "Balochi", "Pashto"],
      language: [
        { title: "Urdu", date: "12 Sep, 2021", side: "Right" },
        { title: "English", date: "12 Sep, 2021", side: "Right Side" },
        { title: "Arabic", date: "12 Sep, 2021", side: "Right Side" },
      ],
      value: null,
      selectedBtn: 1,
      options: [{ name: "Left" }, { name: "Right" }],
    };
  },
  methods: {
    LoadData() {
      var axios = require("axios");
      var config = {
        method: "get",
        url:
          "https://raaji.fastech.pk/api/ChatBots/GetByUser/" +
          this.$store.state.user.id,
        headers: {
          Authorization:
            "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJNdWhhbW1hZCIsImVtYWlsIjoiYWxpMTIzQGdtYWlsLmNvbSIsImp0aSI6ImI0ODJjYjIyLTg0YjctNDZmMS05ODQ4LTdkODRjMjI2YjExMSIsImV4cCI6MTY5OTUzMDc1NCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.Emareb1_zFV8KHVLGv2GJABnDlkm5JONtWAYATT5OjM",
        },
      };

      axios(config)
        .then((response) => {
          console.log(response.data);

          this.botData = [];
          // this.images  =  response.data.data.images;
          response.data.data.forEach((elem) => {
            var d = new Date(elem.createdOn);
            elem.createdOn = d.toDateString();
            //  elem.date =  elem.date.split("T")[0];
            this.botData.push(elem);
          });

          // this.botData = response.data.data;
          // // this.images  =  response.data.data.images;

          // console.log("Helloo Motot", this.languages);
        })
        .catch(function (error) {
          swal.fire({
            type: "error",
            title: "  " + error.message,
            text: "Something went wrong with the request, please try later.",
          });
        });
    },

    Edit(url, cbID, title) {
      this.$store.commit("clearList");
      var axios = require("axios");

      var config = {
        method: "get",
        url: "https://raaji.fastech.pk/api/ChatBots/GetByUrl/" + url,
        headers: {
          Authorization:
            "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJNdWhhbW1hZCIsImVtYWlsIjoiYWxpMTIzQGdtYWlsLmNvbSIsImp0aSI6ImI0ODJjYjIyLTg0YjctNDZmMS05ODQ4LTdkODRjMjI2YjExMSIsImV4cCI6MTY5OTUzMDc1NCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.Emareb1_zFV8KHVLGv2GJABnDlkm5JONtWAYATT5OjM",
        },
      };

      console.log("https://raaji.fastech.pk/api/ChatBots/GetByUrl/" + url);

      axios(config)
        .then((response) => {
          console.log("by URL", response.data);
          // this.$store.state.chatDetails = [];
          this.$store.commit("clearChatDetails", null);
          this.$store.commit("setChatBotID", cbID);
          this.$store.commit("setBotName", title);
          var myData = response.data.data;
          myData.chatDetail.options = "";
          this.$store.commit("setChatDetails", myData);
          this.$router.push("/create/" + title);
        })
        .catch(function (error) {
          swal.fire({
            type: "error",
            title: "  " + error.message,
            text: "Something went wrong with the request, please try later.",
          });
        });
    },

    LoadLang() {
      var axios = require("axios");
      var config = {
        method: "get",
        url: "https://raaji.fastech.pk/api/Languages",
        headers: {
          Authorization:
            "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJNdWhhbW1hZCIsImVtYWlsIjoiYWxpMTIzQGdtYWlsLmNvbSIsImp0aSI6ImI0ODJjYjIyLTg0YjctNDZmMS05ODQ4LTdkODRjMjI2YjExMSIsImV4cCI6MTY5OTUzMDc1NCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.Emareb1_zFV8KHVLGv2GJABnDlkm5JONtWAYATT5OjM",
        },
      };

      axios(config)
        .then((response) => {
          // console.log("asasas", response.data);

          this.languages = response.data.data;
        })
        .catch(function (error) {
          swal.fire({
            type: "error",
            title: "  " + error.message,
            text: "Something went wrong with the request, please try later.",
          });
        });
    },

    selectedButton(id) {
      this.selectedBtn = id;
      this.$store.commit("setSelectedLang", this.selectedBtn);
      // console.log(this.selectedBtn);
    },
    modal_open() {
      this.modal = true;
      this.isActive = true;
    },

    close() {
      this.modal = false;
      this.isActive = false;
    },
    createBot() {
      this.$router.push("/bubbleChat/" + this.selectedBtn);
      this.$store.state.list = [];
    },

    modal_Delete(row) {
      //  return console.log(row);
      //  this.$swal('Hello Vue world!!!');

      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          var axios = require("axios");

          var config = {
            method: "delete",
            url: `https://raaji.fastech.pk/api/ChatBots/DeleteChatBot/${row}`,
            headers: {
              Authorization:
                "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJNdWhhbW1hZCIsImVtYWlsIjoiYWxpMTIzQGdtYWlsLmNvbSIsImp0aSI6ImI0ODJjYjIyLTg0YjctNDZmMS05ODQ4LTdkODRjMjI2YjExMSIsImV4cCI6MTY5OTUzMDc1NCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.Emareb1_zFV8KHVLGv2GJABnDlkm5JONtWAYATT5OjM",
            },
          };

          axios(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === "success") {
                this.LoadData();
              } else if (response.data.status === "notallowed") {
                this.$swal({
                  title: "Not Allowed !",
                  text: "You cannot delete this record, it has relative data!",
                  type: "warning",
                  showCancelButton: false,
                  confirmButtonClass: "btn btn-primary btn-fill",
                  confirmButtonText: "Ok",
                  buttonsStyling: false,
                });
              }
            })
            .catch(function (error) {
              this.$swal.fire({
                type: "error",
                title: "  " + error.message,
                text: "Something went wrong with the connection, Please try again later!",
              });
            });
        }
      });
    },
  },
  mounted() {
    if (this.$store.state.user == null) this.$router.push("/login");
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.overlay {
  position: fixed;
  display: block;

  /* color with alpha channel */
  background-color: rgba(0, 0, 0, 0.7); /* 0.7 = 70% opacity */

  /* stretch to screen edges */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.btnContainerr {
  /* max-width: var(--maxWidth); */
  max-width: 960px;
  /* background-color: aqua; */
  margin: auto;
}

.myGrid {
  /* max-width: var(--maxWidth); */
  margin: 0 auto;
  max-width: 960px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10rem, 10rem;
  gap: 1rem;
  padding-top: 2rem;

  grid-template-areas: "card card ";
}
.unSelectedBtn {
  background-color: #fff;
  color: #93117d;
  border-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  padding: 12px 16px;
  border-radius: 12px;
  /* border: 2px solid #93117d; */
  border-radius: 10px;
  font-weight: 500;
  margin: 0 6px;
  font-size: 16px;
  font-weight: 100;

  width: 9rem;
  cursor: pointer;
}
.myBg {
  background: url("../../assets/bg.png");
  height: 100%;
  /* margin-bottom: 4rem; */
}
.card {
  grid-area: card;
  height: 10rem;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border-radius: 15px;
  /* max-width: 960px; */
  /* position: ; */
  /* margin: auto; */
}
.heading_iner {
  font-size: 32px;
  /* line-height: 0px; */
  font-weight: 600;
  color: #93117d;
}
.cross {
  background-color: #93117d;
  border-radius: 39px;
  padding: 9px;
  height: 28px;
}
.para_cross {
  color: #fff;
  margin-top: -10px;
  /* font-size: px; */
}

.text_iner {
  padding: 10px 12px;
  border-radius: 12px;
  margin-bottom: 1rem;
  width: 400px;
}
.addLanguages {
  font-size: 24px;
  /* margin: 4rem 0; */
  color: #93117d;
}
.modal_iner {
  display: flex;

  max-width: 880px !important;
  overflow-y: auto;
}
.class__add {
  margin-bottom: 1.6rem;
  display: flex;
  justify-content: space-between;
}
.save_btn {
  background-color: #93117d;
  padding: 12px 16px;
  border-radius: 12px;
  /* border: 2px solid #93117d; */
  border-radius: 10px;
  margin: auto;
  display: block;
  font-size: 16px;
  font-weight: 100;
  color: white;
  margin-top: 1.2rem;
  width: 19rem;
  cursor: pointer;
}
.modal_btn {
  background-color: #93117d;
  padding: 12px 16px;
  border-radius: 12px;
  /* border: 2px solid #93117d; */
  border-radius: 10px;
  font-weight: 500;
  margin: 0 6px;
  font-size: 16px;
  font-weight: 100;
  color: white;
  width: 8rem;
  cursor: pointer;
}
.widthh {
  width: 880px;
  /* bottom: 0; */
}
.paraa {
  font-size: 17px;
  color: gray;
  line-height: 1.4rem;
}
.modall {
  cursor: pointer !important;
}
.modall {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* box-shadow-padding:; */
  padding: 2rem;
  border-radius: 15px;
  width: fit-content;
  margin: auto;
  position: absolute;
  /* top: 50%; */
  bottom: -6.8rem;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  z-index: 1;
}
.typo__label {
  margin-top: 1rem !important;
}
.main-container {
  padding: 1rem 3rem;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
}
.flexx {
  display: flex;
  justify-content: space-between;
  margin-top: -2rem;
}
.heading {
  margin-bottom: 2rem;
  /* line-height: 0px; */
}

.btns {
  padding-top: 2rem;
  max-width: 960px;
}
.btn {
  background-color: white;
  padding: 14px 16px;
  border-radius: 12px;
  border: 2px solid #93117d;
  border-radius: 10px;
  margin: 0 6px;
  font-size: 16px;
  font-weight: 100;
  color: #000;
  width: 6rem;
  cursor: pointer;
}
.add_btn {
  margin: 2rem 0;
  max-width: 960px;

  background-color: white;
  padding: 14px 16px;
  border-radius: 12px;
  border: 2px solid #93117d;
  border-radius: 10px;
  margin: 0 6px;
  font-size: 16px;
  font-weight: 100;
  color: #000;
  width: 9rem;
  cursor: pointer;
}
.btn:hover {
  background-color: #93117d;
  color: #fff;
}
.add_btn:hover {
  background-color: #93117d;
  color: #fff;
}
</style>
