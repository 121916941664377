import { render, staticRenderFns } from "./ChangePassword.vue?vue&type=template&id=9076af4e&scoped=true&"
import script from "./ChangePassword.vue?vue&type=script&lang=js&"
export * from "./ChangePassword.vue?vue&type=script&lang=js&"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&lang=css&"
import style1 from "./ChangePassword.vue?vue&type=style&index=1&id=9076af4e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9076af4e",
  null
  
)

export default component.exports