<template>
  <div style="margin-right: 20px">
    <p style="padding: 0px 0px; font-size: 0.7rem; margin-left: 2.5rem">
      {{ optionText }}
    </p>
    <div class="admin flexx" style="padding-bottom: 0px; margin-bottom: 10px">
      <div class="avatar">
        <img
          class="avatar_inner_admin"
          src="https://auratraaj.com/englishmhm/img/raaji.png"
          alt=""
        />
      </div>

      <div class="admin_msg_outer">
        <p class="admin_msg">
          {{ text }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["text", "optionText", "side"],
};
</script>
<style scoped>
.avatar {
  align-items: flex-end;
  display: flex;
  margin: 0 0rem;
  /* position: relative; */
  top: 2rem;
}
.avatar_inner_admin {
  height: 30px;
  width: 30px;
  border-radius: 50px;
}
.admin_msg_outer {
  background-color: rgb(252, 249, 249);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 0.5rem;
  border-radius: 15px;
  background-color: #93117d;
  color: #fff;
  margin-left: 10px;

  /* left: 0.2rem; */
  /* position: relative; */
}
.flexx {
  display: flex;
  /* justify-content: space-between; */

  margin-bottom: 1rem;
}
.admin_msg {
  font-size: 20px;
}
</style>
