<template>
  <div>
    <div class="myBg">
      <div class="myGrid">
        <div class="card">
          <div class="main_card">
            <div class="header">
              <div class="icon">
                <i class="fas fa-arrow-left"></i>
              </div>
              <h2 class="heading_iner">Where are you from?</h2>
            </div>
            <div class="main_body">
              <div class="text_box">
                <div class="msg">
                  <h2 class="main_msg">Message:</h2>
                </div>
                <div class="input_outer">
                  <!-- <textarea
                    name=""
                    id=""
                    rows="0"
                    class="input_one"
                    placeholder="I am from Karachi"
                  ></textarea> -->
                  <input
                    type="text"
                    class="input_one"
                    placeholder="I am from Karachi"
                  />
                </div>
              </div>
              <div class="options">
                <div class="msg">
                  <h2 class="main_msg">Options:</h2>
                </div>
                <div class="input_fileds">
                  <div class="less_padding">
                    <div class="fields field_1">
                      <i class="fas fa-circle"></i>

                      <input
                        type="text"
                        class="input_two"
                        placeholder="Your knowledge"
                      />
                      <i class="fas fa-times"></i>
                    </div>
                    <div class="fields field_2">
                      <i class="fas fa-circle"></i>

                      <input
                        type="text"
                        class="input_two"
                        placeholder="What do you like?"
                      />
                      <i class="fas fa-times"></i>
                    </div>
                    <div class="fields field_3">
                      <i class="fas fa-circle"></i>

                      <input
                        type="text"
                        class="input_two"
                        placeholder="Your mission"
                      />
                      <i class="fas fa-times"></i>
                    </div>

                    <div v-if="shown" class="fields field_4">
                      <i class="fas fa-circle"></i>

                      <input
                        type="text"
                        class="input_two"
                        placeholder="Your mission"
                      />
                      <i class="fas fa-times"></i>
                    </div>
                  </div>
                  <div class="fields field_4">
                    <i class="fas fa-circle"></i>

                    <input
                      type="text"
                      class="input_two"
                      placeholder="How long you've been doing this?"
                    />
                    <div class="btns">
                      <button class="btn" @click="show_field">+</button>
                    </div>
                  </div>
                </div>
                <div class="img">
                  <img
                    src="https://www.bksb.co.uk/wp-content/uploads/2000/03/girls-studying.jpg"
                    alt=""
                    class="img_inner"
                  />
                </div>
                <div class="btns_s">
                  <button class="add_btn" @click="modal_open">
                    Upload media
                  </button>
                  <button class="add_btn" @click="modal_open">
                    Attach Audio
                  </button>
                </div>
                <div class="save_btn">
                  <button class="save_btn_inner">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      shown: false,
      placeholder: "My name is Raaji I'm very happy to meet you",
    };
  },
  methods: {
    show_field() {
      this.shown = true;
    },
  },
};
</script>
<style scoped>
.myBg {
  /* overflow: hidden; */

  height: 100vh;
  background: url("../../assets/bgg.svg");
}
.myGrid {
  /* max-width: var(--maxWidth); */
  margin: 0 auto;
  max-width: 540px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10rem, 10rem;
  gap: 1rem;
  padding-top: 2rem;
  grid-template-areas: "card card ";
}
.main_card {
  padding: 1rem 2rem;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
}
.card {
  /* overflow: auto; */
  grid-area: card;
  position: relative;
  height: 37rem;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border-radius: 15px;
}
.header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-left: -8rem;
}
.heading_iner {
  font-size: 22px;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #93117d;
}
.input_one {
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
  width: 100%;
  font-family: "Roboto", sans-serif;
  padding: 0.2rem 0.2rem;
  font-size: 25px;
  resize: none;
}
.input_two {
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
  width: 87%;
  padding: 0.1rem 0;
  font-size: 20px;
}
.fields {
  padding: 0.6rem 0;
  display: flex;
  align-items: center;
}
.fa-circle {
  font-size: 10px;
  opacity: 0.5;
  margin: 0 0.6rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.fa-times {
  margin-left: 1rem;
}
.btns {
  /* padding: 0 0.6rem; */
  align-items: center;
  margin-right: 0rem;
  margin-top: -1rem;
  display: flex;
  justify-content: center;
}
.btn {
  background-color: #93117d;
  border-radius: 12px;
  border-radius: 50px;
  border-color: #93117d;
  height: 2rem;
  width: 2rem;
  margin: 0 6px;
  font-size: 26px;
  font-weight: 100;
  color: #fff;
  cursor: pointer;
  margin: 0.6rem 0;
}
.less_padding {
  padding-right: 0.4rem;
}
.img {
  display: flex;
  justify-content: center;
}
.img_inner {
  width: 260px;
  height: 120px;
  border-radius: 12px;
}
.btns_s {
  padding-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  /* max-width: 960px; */
}

.add_btn {
  margin: 2rem 0;
  /* max-width: 960px; */
  background-color: white;
  padding: 7px 16px;
  border-radius: 12px;
  border: 2px solid #93117d;
  border-radius: 25px;
  margin: 0 6px;
  font-size: 18px;
  font-weight: 100;
  color: #93117d;
  width: 12.6rem;
  cursor: pointer;
}
.save_btn {
  padding-top: 0.5rem;
}
.save_btn_inner {
  margin: 2rem 0;
  /* max-width: 960px; */
  background-color: #93117d;
  padding: 7px 16px;
  border-radius: 12px;
  border: 2px solid #93117d;
  border-radius: 25px;
  margin: 0 6px;
  font-size: 18px;
  font-weight: 100;
  color: #fff;
  width: 98%;
  cursor: pointer;
}

.input_fileds {
  overflow-y: auto;
  overflow-x: hidden;
  height: 28vh;
  scrollbar-width: none;
}
.input_fileds::-webkit-scrollbar {
  width: 0px;
}
</style>
