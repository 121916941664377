<template>
  <div class="myBg">
    <div class="btnContainerr">
      <!-- <div class="btns">
        <button class="add_btn" @click="modal_open">Create New</button>
      </div> -->
    </div>
    <div class="myGrid">
      <div class="card">
        <!-- modal -->

        <!-- modal end -->

        <div class="main-container">
          <div class="outer_img">
            <img class="iner_img" src="../../assets/bubbleChat.png" alt="" />
          </div>
          <div class="heading">
            <h2 class="heading_iner">Start building a chat bot</h2>
          </div>
          <div class="input_forms">
            <div class="label">
              <label class="label_iner"> Bot Title</label>
            </div>
            <div class="input_outer">
              <input type="text" v-model="title" class="input_one" />
            </div>
            <div class="label_tow">
              <label class="label_iner">Bot Url</label>

              <div class="input_outer flexx">
                <p class="para">https://www.auratraj.co/bots/</p>
                <input type="text" v-model="url" class="input_two" />
              </div>
            </div>
            <div class="btns">
              <div class="btn" @click="createBot()">
                <i :class="this.icon"></i>
              </div>
            </div>
          </div>
          <!-- main container end -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      modal: false,
      text: "",
      title: null,
      url: null,
      value: null,
      options: [{ name: "Left" }, { name: "Right" }],
      icon: "fas fa-plus",
      addIcon: "fas fa-plus",
      spinnerIcon: "fas fa-circle-notch fa-spin",
    };
  },
  methods: {
    modal_open() {
      this.modal = true;
    },
    Goto() {
      this.$router.push("/botPage");
    },

    close() {
      this.modal = false;
    },
    createBot() {
      try {
        if (this.title == null || this.url == null) {
          this.$swal.fire({
            type: "error",
            icon: "error",
            title: "Error",
            text: "Please fill the details",
          });
          return;
        }

        if (this.url.indexOf(" ") > -1) {
          this.$swal.fire({
            type: "error",
            icon: "error",
            title: "Error",
            text: "Please remove white spaces from URL",
          });
          return;
        }

        var axios = require("axios");
        this.icon = this.spinnerIcon;

        var config = {
          method: "post",
          url: "https://raaji.fastech.pk/api/ChatBots/CreateBot",
          headers: {
            Authorization:
              "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJNdWhhbW1hZCIsImVtYWlsIjoiYWxpMTIzQGdtYWlsLmNvbSIsImp0aSI6ImI0ODJjYjIyLTg0YjctNDZmMS05ODQ4LTdkODRjMjI2YjExMSIsImV4cCI6MTY5OTUzMDc1NCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.Emareb1_zFV8KHVLGv2GJABnDlkm5JONtWAYATT5OjM",
          },
          data: {
            id: 0,
            title: this.title,
            url: this.url,
            languageId: this.$store.state.selectedLang,
            language: null,
            createdOn: "2021-11-09T00:00:00",
            chatDetails: null,
            chatOptions: null,
          },
        };

        axios(config)
          .then((response) => {
            console.log(response.data);
            if (response.data.status === "success") {
              this.$store.commit("clearChatDetails", null);
              this.$store.commit("setBotName", this.title);
              this.$store.commit("setChatBotID", response.data.chatBotId);
              this.$router.push("/botPage");
            } else {
              this.icon = this.addIcon;
              this.$swal.fire({
                type: "error",
                icon: "error",
                title: "Error",
                text: "Bot with same url already exists, please use another url.",
              });
            }
          })
          .catch(function (error) {
            this.icon = this.addIcon;
            this.$swal.fire({
              type: "error",
              title: "  " + error.message,
              text: "Something went wrong with the connection, Please try again later!",
            });
          });
      } catch (error) {
        this.icon = this.addIcon;
        console.log("bubblechat", error);
        swal.fire({
          type: "error",
          title: "  " + error.message,
          text: "Something went wrong with the request, please try later.",
        });
      }
    },
  },
  created() {
    console.log("chatttt", this.$store.state.selectedLang);
  },
  mounted() {
    if (this.$store.state.user == null) this.$router.push("/login");
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.btnContainerr {
  /* max-width: var(--maxWidth); */
  max-width: 960px;
  /* background-color: aqua; */
  padding-top: 2rem;

  margin: auto;
}

.myGrid {
  /* max-width: var(--maxWidth); */
  margin: 0 auto;
  max-width: 540px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10rem, 10rem;
  gap: 1rem;
  padding-top: 2rem;

  grid-template-areas: "card card ";
}

.myBg {
  background: url("../../assets/bg.png");
  height: 100vh;
  /* margin-bottom: 4rem; */
}

.card {
  grid-area: card;
  position: relative;
  height: 32rem;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border-radius: 15px;
}
.heading_iner {
  font-size: 22px;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  display: flex;
  color: rgb(116, 113, 113);
  margin-top: -1rem;
  letter-spacing: 1px;
}

.bottom_class {
  position: absolute;
  bottom: 0;

  width: 500px;
}
.width_full {
  width: 100%;
  display: flex;
  justify-content: center;
}
.auto_msg {
  padding: 0.6rem;
  margin: 0.8rem 0;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.text_iner {
  padding: 10px 12px;
  border-radius: 12px;
  margin-bottom: 1rem;
  width: 400px;
}
.addLanguages {
  font-size: 24px;
  /* margin: 4rem 0; */
  color: #93117d;
}

.modall {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* box-shadow-padding:; */
  padding: 2rem;
  cursor: pointer !important;
  border-radius: 15px;
  width: fit-content;
  margin: auto;
  position: absolute;
  /* top: 50%; */
  bottom: -7.4rem;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  z-index: 1;
}
.typo__label {
  margin-top: 1rem !important;
}
.main-container {
  padding: 1rem 1rem;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
}
.flexx {
  display: flex;
  /* justify-content: space-between; */
  /* margin: 1rem 0; */
}

.btns {
  /* padding: 1rem 0; */
  align-items: center;
  display: flex;
  justify-content: center;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #93117d;
  border-radius: 12px;
  border-radius: 50px;
  border-color: #93117d;
  height: 3rem;
  width: 3rem;
  margin: 0 6px;
  font-size: 36px;
  font-weight: 100;
  color: #fff;
  cursor: pointer;
  margin: 0.6rem 0;
}

.btn:hover {
  background-color: #93117d;
  color: #fff;
}
.outer_img {
  display: flex;
  justify-content: center;
  margin-top: -2rem;
}
.iner_img {
  width: 300px;
  height: 300px;
  opacity: 0.4;
}
.label_iner {
  font-size: 14px;
  font-weight: 400;

  color: gray;

  letter-spacing: 1px;
}
.input_one {
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
  width: 100%;
  /* padding: 0.2rem 0; */
  font-size: 21px;
}

.input_forms {
  padding: 0 2rem;
}
.input_two {
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
  width: 10.8rem;
  /* padding: 0.6rem 0; */
  font-size: 19px;
  color: rgb(100, 100, 100);
}
.label_tow {
  margin: 1.4rem 0;
}
.para {
  color: rgb(100, 100, 100);
}
</style>
