<template>
  <div class="main">
    <div class="img_banner">
      <img class="img" src="../../assets/login_cover.jpg" alt="" />
    </div>
    <div class="form_banner">
      <!-- <row> -->
      <div class="center">
        <div class="form control">
          <img class="imggg" src="../../assets/raaji_logo.svg" alt="" />

          <div class="main_text head">
            <h2 class="head">Login to continue</h2>
          </div>
          <div>
            <label class="form_label">Enter Email</label>

            <input
              class="typo__label"
              v-model="myObj.email"
              type="email"
              @focusout="CheckEmail()"
              placeholder="Enter your email"
            />
            <small v-if="errors.email == true" class="error"
              >Please enter valid email address.</small
            >
          </div>
          <div style="margin-top: 1rem">
            <label class="form_label">Enter Password</label>

            <input
              class="typo__label"
              v-model="myObj.password"
              type="password"
              @focusout="CheckPassword()"
              placeholder="Enter your Password"
            />
            <small v-if="errors.password == true" class="error"
              >Please enter password.</small
            >
          </div>

          <div class="btn">
            <button class="btn_iner" @click="Login">Login</button>
          </div>
        </div>
      </div>
      <!-- </row> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      myObj: {
        email: "",
        password: "",
      },
      errors: {
        email: false,
        password: false,
      },
      email: "",
      password: "",
    };
  },
  methods: {
    CheckEmail() {
      // console.log("Hello");
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      console.log("Hello Email");
      console.log(this.myObj.email);

      if (
        re.test(String(this.myObj.email).toLowerCase()) &&
        this.myObj.email !== ""
      ) {
        return (this.errors.email = false);
      } else {
        return (this.errors.email = true);
      }
    },
    CheckPassword() {
      if (this.myObj.password !== "") {
        return (this.errors.password = false);
      } else {
        return (this.errors.password = true);
      }
    },

    Login() {
      var axios = require("axios");
      // this.$router.push("/");
      this.CheckEmail();
      this.CheckPassword();
      //  return console.log(this.CheckPassword(),this.CheckEmail());
      if (this.CheckEmail() == true || this.CheckPassword() == true) {
        console.log("Checking");
        return 0;
      } else if (this.CheckEmail() == false || this.CheckPassword() == false) {
        // return   console.log("Helloo");
        var url = "https://raaji.fastech.pk/api/Login/UserLogin";
        axios
          .post(url, this.myObj)
          .then((res) => {
            console.log(res);
            if (res.data.status == "success" && res.data.data !== "not exist") {
              console.log("Hellloo", res.data);
              this.$store.commit("setUser", res.data.data);
              //      this.$store.commit(
              //   "updateToken",
              //  this.$store.state.token
              // );
              this.$router.push("/");
              //  let resolved = this.$router.resolve("/Home");
              //if (resolved.route.name != "404")

              //  console.log("Router: ", resolved.route.name);
            } else {
              alert("Invalid login");
              //this.saving = true;
            }
          })
          .catch(function (error) {
            console.log(error.message);
            if (error.message === "Network Error") {
              //  swal.fire({
              //   type: 'error',
              //    title: '  '   error.message,
              //   text: 'Something went wrong with the connection, Please try again later!',
              // })
            } else {
              alert("Invalid login");
            }
            ////console.log.error(error);
          })
          .finally(() => {
            //this.sign = "";
            //this.saving = false;
          });

        //this.$router.push("/");
      } else {
        alert("Invalid login credentials");
      }
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap");
.main_text {
  text-align: center;
  padding: 2rem 0;
}
.main {
  display: grid;
  background-color: white;
  /* gap: 2rem; */
  grid-template-columns: 1fr 1fr;
  /* grid-template-columns: repeat(auto-fit, minmax(533px, 1fr)); */
  /* overflow: hidden; */
}
.imggg {
}
.btn {
  padding: 3rem 0;
  margin: 0 auto;
  width: 100%;
}
.img {
  height: 100vh;
  width: 65vw;

  /* grid-column: span 2 / auto; */
}
.control {
  width: 70%;
  margin: auto;
}
.typo__label {
  padding: 1rem 1rem;
  font-size: 24px;
  width: 100%;
  border-radius: 12px;
}
.btn_iner {
  padding: 1rem 2rem;
  font-size: 22px;
  border-radius: 12px;
  width: 100%;
  color: white;
  cursor: pointer;
  background-color: #93117d;
}
.center {
  display: grid;
  height: 100%;
}
.form_label {
  font-size: 24px;
}
.error {
  color: red;
}
.head {
  font-size: 32px;
  font-family: "Poppins";

  font-weight: bolder;
}

@media screen and (max-width: 500px) {
  .control {
    width: 100% !important;
    padding: 1rem !important;
  }
  .main_text {
    padding: 1rem 0;
  }
  .head {
    margin: 0 !important;
  }
  .img_banner {
    display: none;
  }
  .form_label {
    font-size: 18px;
  }
  .head {
    font-size: 22px;
    font-family: "Poppins";

    font-weight: bolder;
  }
  .imggg {
    width: 16rem;
    margin: auto;
    display: flex;
  }
  .typo__label {
    padding: 1rem;
    font-size: 18px;
    border-radius: 12px;
  }

  .main {
    display: grid;
    /* gap: 2rem; */
    grid-template-columns: 1fr;
    overflow: hidden;
  }
}

@media screen and (max-width: 1025px) {
  .img {
    height: 95vh;
    width: 50vw;
    /* object-fit: contain; */
  }
  .imggg {
    width: 16rem;
    margin: auto;
    display: flex;
  }
}
</style>
