import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import Home from "../views/Home.vue";
import HomeLayout from "../views/HomeLayout.vue";
import User from "../components/users/User.vue";
import ChangeP from "../components/users/ChangePassword.vue";
import chatbot from "../components/ChatBot/chatbot.vue";
import createBot from "../components/createBot/createBot.vue";
import login from "../components/login/login.vue";
import bubbleChat from "../components/bubbleChat/bubbleChat.vue";
import talkingBot from "../components/talkingBot/talkingBot.vue";
import talkingBot1 from "../components/talkingBot/talkingBot1.vue";
import talkingBot2 from "../components/talkingBot/talkingBot2.vue";
import botPage from "../components/botPage/botPage.vue";
import botResponse from "../components/botPage/botResponse.vue";
import adress from "../components/adress/adress.vue";
import information from "../components/information/information.vue";
import preview from "../components/talkingBot/preview.vue";
import VueSweetalert2 from "vue-sweetalert2";
import { BootstrapVue, BRow, BCol } from "bootstrap-vue";
import Notifications from "vue-notification";

Vue.use(Notifications);

Vue.use(BootstrapVue);
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/user",
    name: "user",
    component: User,
  },
  {
    path: "/ChangeP",
    name: "ChangeP",
    component: ChangeP,
  },
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/bubbleChat/:lang",
    name: "bubbleChat",
    component: bubbleChat,
  },
  {
    path: "/adress",
    name: "adress",
    component: adress,
  },
  {
    path: "/information",
    name: "information",
    component: information,
  },

  {
    path: "/botPage",
    name: "botPage",
    component: botPage,
  },
  {
    path: "/botResponse",
    name: "botResponse",
    component: botResponse,
  },
  {
    path: "/talkingBot",
    name: "talkingBot",
    component: talkingBot,
  },
  {
    path: "/create/:lang",
    name: "talkingBot1",
    component: talkingBot1,
  },
  {
    path: "/talkingBot2",
    name: "talkingBot2",
    component: talkingBot2,
  },
  {
    path: "/bot/:name",
    name: "preview",
    component: preview,
  },
  {
    path: "/createBot",
    name: "createBot",
    component: createBot,
  },
  {
    path: "/chatbot",
    name: "chatbot",
    component: chatbot,
  },
  {
    path: "/res",
    name: "HomeLayout",
    component: HomeLayout,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
